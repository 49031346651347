<template>
  <div class="columns is-centered">
    <div class="column is-5">
      <div
        class="content-box"
        :class="{
          'frosted-background-static': $isSafari,
          'frosted-background': !$isSafari,
        }"
      >

        <template v-if="state === 0">
          <textarea class="textarea" placeholder="mots..." v-model="form.mots"></textarea>
          <button class="button" @click="sprotch">SPRÖTCH</button>
        </template>

        <div class="" v-else-if="state === 1">
          {{ current_word_index }}/{{ total_words }}<br/>
          <br/>
          <div class="has-text-centered">
            <button class="button is-loading" disabled>-</button>
          </div>
        </div>

        <div v-else-if="state === 2">
          {{ current_word_index }}/{{ total_words }}<br/>
          <input type="text" class="input is-large" readonly :value="cur_mot" style="margin-bottom: 4px;"/>

          <table class="table">
            <thead>
              <tr>
                <th>Mot</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(res, i) in cur_resultats" :key="'resultat_' + i">
                <td>{{ res.mot }}</td>
                <td>{{ res.score }}</td>
              </tr>
            </tbody>
          </table>

          <button
            class="button is-large is-danger"
            style="margin-right: 6px;"
            @click="boutonRejeter"
          >REJETER</button>
          <button
            class="button is-large is-success"
            :disabled="garderImpossible"
            @click="boutonGarder"
          >GARDER</button>
          <br/>
          <button class="button is-small" @click="stopAfficheGo">stop j'en ai marre</button>
        </div>

        <pre v-else-if="state === 3">
          <textarea class="textarea" v-model="output_go" readonly></textarea>
          <textarea class="textarea" v-model="output_rejets" readonly></textarea>
        </pre>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.content-box {
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.11);
  border-radius: 12px;
  padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}
</style>

<script>
const liste_rejet = {
  /*
  "Poule de luxe": true,
  "Position sexuelle": true,
  "Porter la culotte": true,
  "Pornographie": true,
  "Poppers": true,
  "Pisser dans un violon": true,
  "Pipe": true,
  "Phantasme": true,
  "Péter plus haut que son cul": true,
  "Peste noire": true,
  "Peste": true,
  "Pénis": true,
  "Peine de mort": true,
  "Pas besoin de tortiller du cul pour chier droit": true,
  "Partir en couille": true,
  "Parler français comme une vache espagnole": true,
  "Parle à mon cul, ma tête est malade": true,
  "Paludisme": true,
  "Orgasme": true,
  "Organisation armée secrète": true,
  "Opium": true,
  "Oie blanche": true,
  "Obésité": true,
  "Nicotine": true,
  "Nazisme": true,
  "Narcolepsie": true,
  "Morphine": true,
  "Mise en examen": true,
  "Méthamphétamine": true,
  "Masturbation": true,
  "Massacre de la Saint-Barthélemy": true,
  "Maladie de Parkinson": true,
  "Maladie de Lyme": true,
  "Maladie d'Alzheimer": true,
  "LSD": true,
  "Les Mondes engloutis": true,
  "Les Misérables": true,
  "Les Minions": true,
  "Les Mille et une nuits": true,
  "Les mains dans les poches": true,
  "Les Lumières de la ville": true,
  "Les Liaisons dangereuses": true,
  "Les Hommes préfèrent les blondes": true,
  "Les Hommes du président": true,
  "Les Hauts de Hurlevent": true,
  "Les Guignols de l'Info": true,
  "Les Griffes de la nuit": true,
  "Les grands esprits se rencontrent": true,
  "Les Garçons et Guillaume, à table !": true,
  "Les Frères Lumière": true,
  "Les Fourberies de Scapin": true,
  "Les Fleurs du mal": true,
  "Les Feux de l'amour": true,
  "Les Faux-monnayeurs": true,
  "Les Envahisseurs": true,
  "Les Entrechats": true,
  "Les Enfoirés": true,
  "Les Enfants du Paradis": true,
  "Les Échos": true,
  "Les Droits de l'Homme": true,
  "Les Douze salopards": true,
  "Les doigts dans le nez": true,
  "Les Dix Commandements": true,
  "Les Dieux sont tombés sur la tête": true,
  "Les Diaboliques": true,
  "Les dés sont pipés": true,
  "Les Démons de minuit": true,
  "Les Demoiselles de Rochefort": true,
  "Les cordonniers sont les plus mal chaussés": true,
  "Les Contes de la Crypte": true,
  "Les Colons de Catane": true,
  "Les cinq pilliers de l'Islam": true,
  "Les chiens ne font pas des chats": true,
  "Les Chevaliers du Zodiaque": true,
  "Les Chariots de feu": true,
  "Les Chadoks": true,
  "Les carottes sont cuites": true,
  "Les Bronzés font du ski": true,
  "Les bras m'en tombent": true,
  "Les bons comptes font les bons amis": true,
  "Les Bidochons": true,
  "Les Aventuriers de l'arche perdue": true,
  "Les Aventures de Rabbi Jacob": true,
  "Les Autres": true,
  "Les Animaux fantastiques": true,
  "Les Anges de la Téléréalité": true,
  "Les affaires sont les affaires": true,
  "Les absents ont toujours tort": true,
  "L'erreur est humaine": true,
  "L'Équipe": true,
  "Léonard de Vinci": true,
  "Léon": true,
  "Lentilles de contact": true,
  "L'Ennemi public nº 1": true,
  "L'Enfant sauvage": true,
  "L'Empire des sens": true,
  "L'Empire contre-attaque": true,
  "L'Empereur, sa femme et le petit prince": true,
  "Leia Organa": true,
  "Légion étrangère": true,
  "Légion d'honneur": true,
  "Légendes d'automne": true,
  "L'échapper belle": true,
  "LeBron James": true,
  "Leboncoin": true,
  "Le chant du cygne": true,
  "Le bras droit": true,
  "Le Vieil homme et la mer": true,
  "Le Troisième homme": true,
  "Le Transperceneige": true,
  "Le Train sifflera trois fois": true,
  "Le Tour du monde en 80 jours": true,
  "Le torchon brûle": true,
  "Le Tombeau des lucioles": true,
  "Le temps, c’est de l’argent": true,
  "Le Tartuffe": true,
  "Le Spleen de Paris": true,
  "Le sort en est jeté": true,
  "Le Songe d'une nuit d'été": true,
  "Le Silence des agneaux": true,
  "Le serpent qui se mord la queue": true,
  "Le Serment du Jeu de Paume": true,
  "Le Septième Sceau": true,
  "Le Secret de Brokeback Mountain": true,
  "Le Scaphandre et le papillon": true,
  "Le Salaire de la peur": true,
  "Le Sacre du Printemps": true,
  "Le Rouge et le Noir": true,
  "Le Réveil de la Force": true,
  "Le Retour du Jedi": true,
  "Le Radeau de La Méduse": true,
  "Le Quai des brumes": true,
  "Le Projet Blair Witch": true,
  "Le Prisonnier": true,
  "Le Prestige": true,
  "Le Pont Mirabeau": true,
  "Le Pont de la rivière Kwaï": true,
  "Le Pianiste": true,
  "Le Père Noël est une ordure": true,
  "Le Père Goriot": true,
  "Le Patient anglais": true,
  "Le Parrain": true,
  "Le Pape François": true,
  "Le Pacte des loups": true,
  "Le Nom de la rose": true,
  "Le Mont-Saint-Michel": true,
  "Le Monde ne suffit pas": true,
  "Le Monde du Silence": true,
  "Le Monde": true,
  "Le mieux est l’ennemi du bien": true,
  "Le Meilleur des mondes": true,
  "Le Livre de la jungle": true,
  "Le Lion est mort ce soir": true,
  "Le Lièvre et la tortue": true,
  "Le Lauréat": true,
  "Le Lac des cygnes": true,
  "Le Labyrinthe de Pan": true,
  "Le Juste Prix": true,
  "Le Jeu de l'amour et du hasard": true,
  "Le Hollandais Volant": true,
  "Le Grand blond avec une chaussure noire": true,
  "Le Grand bleu": true,
  "Le Gendarme de Saint-Tropez": true,
  "Le Figaro": true,
  "Le Faucon maltais": true,
  "Le Fantôme de l'opéra": true,
  "Le Fabuleux destin d'Amélie Poulain": true,
  "Le dothraki": true,
  "Le Dormeur du val": true,
  "Le Docteur Jivago": true,
  "Le Discours d'un roi": true,
  "Le Dîner de cons": true,
  "Le Dictateur": true,
  "Le Diable s'habille en Prada": true,
  "Le Dernier tango à Paris": true,
  "Le Dernier métro": true,
  "Le Dernier jour d'un condamné": true,
  "Le Dernier empereur": true,
  "Le Dernier des Mohicans": true,
  "Le Déjeuner sur l'herbe": true,
  "Le Cuirassé Potemkine": true,
  "Le Crime était presque parfait": true,
  "Le Corbusier": true,
  "Le collège fou, fou, fou": true,
  "Le Cinquième Élément": true,
  "Le Cid": true,
  "Le chien aboie, la caravane passe": true,
  "Le Château Ambulant": true,
  "Le Chat du Cheshire": true,
  "Le Chant des partisans": true,
  "Le Cercle des poètes disparus": true,
  "Le Capitaine Nemo": true,
  "Le Canard enchaîné": true,
  "Le Bourgeois gentilhomme": true,
  "Le Bon, la Brute et le Truand": true,
  "Le Bon Roi Dagobert": true,
  "Le Bateau ivre": true,
  "Le Barbier de Séville": true,
  "Le Bal des vampires": true,
  "Le 36 du mois": true,
  "Lawrence d'Arabie": true,
  "L'avoir dans l'os": true,
  "Lavoir": true,
  "Laurel et Hardy": true,
  "L'Auberge espagnole": true,
  "Last Action Hero": true,
  "L'Arnaque": true,
  "L'Arnacœur": true,
  "Larmes artificielles": true,
  "L'Armée des ombres": true,
  "L'Armée des douze singes": true,
  "L'Arme fatale": true,
  "Largo Winch": true,
  "L'arbre qui cache la forêt": true,
  "L'Anneau des Nibelungen": true,
  "Langue maternelle": true,
  "Langue de vipère": true,
  "L'Angélus": true,
  "Lanfeust de Troy": true,
  "Lancelot du Lac": true,
  "Lampe de chevet": true,
  "Lampe à pétrole": true,
  "L'Amour aux temps du choléra": true,
  "Lame": true,
  "Lambada": true,
  "L'Aile ou la cuisse": true,
  "Laid comme un pou": true,
  "Laïcité": true,
  "L'Agence tous risques": true,
  "L'Âge de glace": true,
  "Lady Gaga": true,
  "Lacoste": true,
  "Lâcher une caisse": true,
  "Lâcher le morceau": true,
  "Lâcher la proie pour l'ombre": true,
  "Lâcher la grappe": true,
  "Lac Victoria": true,
  "Labrador": true,
  "La Vie est un long fleuve tranquille": true,
  "La Vie est belle": true,
  "La Vie d'Adèle": true,
  "La vengeance est un plat qui se mange froid": true,
  "La vache qui rit": true,
  "La Vache et le prisonnier": true,
  "La Tour infernale": true,
  "La Torah": true,
  "La Toison d'Or": true,
  "La semaine des quatre jeudis": true,
  "La Salsa du Démon": true,
  "La Revanche d'une blonde": true,
  "La Réunion": true,
  "La Reine-sorcière (belle-mère de Blanche-Neige)": true,
  "La Reine Margot": true,
  "La Reine de cœur": true,
  "La Règle du jeu": true,
  "La raison du plus fort est toujours la meilleure": true,
  "La queue entre les jambes": true,
  "La Quête de l'oiseau du temps": true,
  "La Quatrième dimension": true,
  "La Prisonnière du désert": true,
  "La plume est plus forte que l'épée": true,
  "La Planète des singes": true,
  "La peur donne des ailes": true,
  "La Petite Maison dans la prairie": true,
  "La Petite Boutique des horreurs": true,
  "La peste ou le choléra": true,
  "La parole est d’argent et le silence est d’or": true,
  "La nuit, tous les chats sont gris": true,
  "La nuit porte conseil": true,
  "La Nuit du chasseur": true,
  "La Nuit des morts-vivants": true,
  "La Nuit au musée": true,
  "La Nuit américaine": true,
  "La Mort aux trousses": true,
  "La montagne a accouché d'une souris": true,
  "La Môme": true,
  "La mettre en sourdine": true,
  "La mer à boire": true,
  "La Mémoire dans la peau": true,
  "La meilleure défense c'est l'attaque": true,
  "La Marseillaise": true,
  "La marquise de Merteuil": true,
  "La Marche de l'Empereur": true,
  "La main dans le sac": true,
  "La Liste de Schindler": true,
  "La Ligne verte": true,
  "La Liberté guidant le peuple": true,
  "La La Land": true,
  "La Jeune Fille à la perle": true,
  "La huitième merveille du monde": true,
  "La Haine": true,
  "La Guerre est déclarée": true,
  "La Guerre du feu": true,
  "La Guerre des boutons": true,
  "La Grande vadrouille": true,
  "La goutte d'eau qui fait déborder le vase": true,
  "La Fureur de Vivre": true,
  "La French": true,
  "La fortune sourit aux audacieux": true,
  "La Folie des grandeurs": true,
  "La Flûte enchantée": true,
  "La fin justifie les moyens": true,
  "La fin des haricots": true,
  "La Fièvre du samedi soir": true,
  "La Femme du boulanger": true,
  "La Fée Clochette": true,
  "La Famille Bélier": true,
  "La Divine Comédie": true,
  "La Dernière tentation du Christ": true,
  "La Défense": true,
  "La croix et la bannière": true,
  "La Croix": true,
  "La critique est aisée mais l’art est difficile": true,
  "La Couleur pourpre": true,
  "La Couleur des sentiments": true,
  "La Cité de la peur": true,
  "La Chevauchée fantastique": true,
  "La cerise sur le gâteau": true,
  "La Cantatrice chauve": true,
  "La Cage aux folles": true,
  "La Boum": true,
  "La bouche en cœur": true,
  "La Belle et le Clochard": true,
  "La Belle au bois dormant": true,
  "La bave du crapaud n'atteint pas la blanche colombe": true,
  "L’oisiveté est la mère de tous les vices": true,
  "L’occasion fait le larron": true,
  "L’homme est un loup pour l’homme": true,
  "L’habitude est une seconde nature": true,
  "L’habit ne fait pas le moine": true,
  "L’exactitude est la politesse des rois": true,
  "L’espoir fait vivre": true,
  "L’enfer est pavé de bonnes intentions": true,
  "L’eau a coulé sous les ponts": true,
  "L’avenir appartient à ceux qui se lèvent tôt": true,
  "L’argent n’a pas d’odeur": true,
  "L’appétit vient en mangeant": true,
  "L’amour est aveugle": true,
  "L.A. Confidential": true,
  "Kurt Cobain": true,
  "Kung Fu Panda": true,
  "Genouillère": true,
  "Génocide": true,
  "Gengis Khan": true,
  "Genève": true,
  "Génération Y": true,
  "Gène": true,
  "Geler à pierre fendre": true,
  "Gatsby le Magnifique": true,
  "Gargoyles": true,
  "Gargantua": true,
  "Garder un chien de sa chienne": true,
  "Garder la tête froide": true,
  "Garde à vue": true,
  "Galop": true,
  "Galilée": true,
  "Fuseau horaire": true,
  "Fumer comme un pompier": true,
  "Full metal jacket": true,
  "Fukushima": true,
  "Fromage de chèvre": true,
  "Fromage blanc": true,
  "Froid de canard": true,
  "Friends": true,
  "Friedrich Nietzsche": true,
  "Frida Kahlo": true,
  "Frère Jacques": true,
  "Frère": true,
  "Frédéric Chopin": true,
  "Freddy Krueger": true,
  "Freddie Mercury": true,
  "Frapper à la bonne porte": true,
  "François Mitterrand": true,
  "François Hollande": true,
  "François Fillon": true,
  "Franco-français": true,
  "Franc-maçonnerie": true,
  "Franck Ribéry": true,
  "France Inter": true,
  "France Info": true,
  "France Gall": true,
  "France 2": true,
  "Français": true,
  "Frais comme un gardon": true,
  "Fraggle Rock": true,
  "Fraction": true,
  "Fouiller": true,
  "Foudre": true,
  "Fou de Bassan": true,
  "Fou à lier": true,
  "Fort comme un Turc": true,
  "Fontaine de jouvence": true,
  "Foire": true,
  "Foie gras": true,
  "Foie": true,
  "FMI (Fonds monétaire international)": true,
  "Florent Pagny": true,
  "Florence Arthaud": true,
  "Myope": true,
  "Les Gardiens de la Galaxie": true,
  "Leonardo DiCaprio": true,
  "Le Graal": true,
  "L'Attaque des tomates tueuses": true,
  "La Bible": true,
  "Flocon": true,
  "Flipper": true,
  "Fleur bleue": true,
  "Flash Gordon": true,
  "Fish 'n' chips": true,
  "Firefly": true,
  "Finlande": true,
  "Fine mouche": true,
  "Final Fantasy": true,
  "Fils": true,
  "Film pornographique": true,
  "Film noir": true,
  "Film de cape et d'épée": true,
  "Film d'action": true,
  "Film catastrophe": true,
  "Filet": true,
  "Filer un mauvais coton": true,
  "Filer en douce": true,
  "Filer à l'anglaise": true,
  "Fil rouge": true,
  "Fil d'Ariane": true,
  "Figure de style": true,
  "Fight Club": true,
  "Fièvre de cheval": true,
  "Fier comme un coq": true,
  "Fier comme Artaban": true,
  "Fibre optique": true,
  "Feuille de chou": true,
  "Fête du Travail": true,
  "Fête de la Bière": true,
  "Festival d'Angoulème": true,
  "Ferry": true,
  "Fernand de Magellan": true,
  "Fermeture éclair": true,
  "Fermer les yeux": true,
  "Fer": true,
  "Fennec": true,
  "Fenêtre sur cour": true,
  "Féminisme": true,
  "Fellation": true,
  "Féculent": true,
  "Fécondation in vitro": true,
  "FC Barcelone": true,
  "Faux jeton": true,
  "Faute de grives, on mange des merles": true,
  "Faute avouée est à moitié pardonnée": true,
  "Faute": true,
  "Faust": true,
  "Fausse note": true,
  "Fausse monnaie": true,
  "Faucon": true,
  "Fauché comme les blés": true,
  "Fatigue": true,
  "Fast and Furious": true,
  "Fascisme": true,
  "Fargo": true,
  "Farce": true,
  "FAQ (Foire aux questions)": true,
  "Fantômas": true,
  "Fantasy": true,
  "Fantastique": true,
  "Fantastic Mr. Fox": true,
  "Faites entrer l'accusé": true,
  "Faites ce que je dis, ne faites pas ce que je fais": true,
  "Fait comme un rat": true,
  "Faisan": true,
  "Fais dodo, Colas mon p'tit frère": true,
  "Fais ce que dois, advienne que pourra": true,
  "Faire une croix sur quelque chose": true,
  "Faire une belle jambe": true,
  "Faire un four": true,
  "Faire un bail": true,
  "Faire trente-six choses à la fois": true,
  "Faire tapisserie": true,
  "Faire son temps": true,
  "Faire profil bas": true,
  "Faire prendre des vessies pour des lanternes": true,
  "Faire patte de velours": true,
  "Faire mouche": true,
  "Faire machine arrière": true,
  "Faire les yeux doux": true,
  "Faire les pieds (à quelqu'un)": true,
  "Faire les cents pas": true,
  "Faire l'enfant": true,
  "Faire le pont (congés)": true,
  "Faire le poirier": true,
  "Faire le point": true,
  "Faire le pied de grue": true,
  "Faire le ménage": true,
  "Faire le joli cœur": true,
  "Faire le dos rond": true,
  "Faire la tête au carré": true,
  "Faire la tête": true,
  "Faire la roue": true,
  "Faire la queue": true,
  "Faire la pluie et le beau temps": true,
  "Faire la planche": true,
  "Faire la part des choses": true,
  "Faire la grasse matinée": true,
  "Faire la fine bouche": true,
  "Faire la bête à deux dos": true,
  "Faire grise mine": true,
  "Faire flèche de tout bois": true,
  "Faire fissa": true,
  "Faire feu de tout bois": true,
  "Faire d'une pierre deux coups": true,
  "Faire des yeux de merlan frit": true,
  "Faire des siennes": true,
  "Faire des pieds et des mains": true,
  "Faire des comptes d'apothicaire": true,
  "Faire de vieux os": true,
  "Faire de l'œil": true,
  "Faire contre mauvaise fortune bon cœur": true,
  "Faire chou blanc": true,
  "Faire chier": true,
  "Faire chanter quelqu'un": true,
  "Faire cavalier seul": true,
  "Faire avancer le schmilblic": true,
  "Faire amende honorable": true,
  "Faim": true,
  "Fahrenheit 451": true,
  "Facebook": true,
  "Fable": true,
  "Fabien Barthez": true,
  "Fa": true,
  "Eyes Wide Shut": true,
  "Expérience de Milgram": true,
  "Expendables": true,
  "Excité comme une puce": true,
  "Excision": true,
  "Évelyne Thomas": true,
  "Europe 1": true,
  "Europe": true,
  "Euro": true,
  "Euphémisme": true,
  "Euclide": true,
  "Études": true,
  "Être tiré par les cheveux": true,
  "Être soupe au lait": true,
  "Être plein aux as": true,
  "Être gai comme un pinson": true,
  "Être fier comme un paon": true,
  "Être de son temps": true,
  "Être verni": true,
  "Être une tête de lard": true,
  "Être une grosse légume": true,
  "Être une grenouille de bénitier": true,
  "Être une bonne poire": true,
  "Être une lumière": true,
  "Être un vieux loup de mer": true,
  "Être un oiseau de mauvais augure": true,
  "Être un chaud lapin": true,
  "Être un panier percé": true,
  "Être un ours mal léché": true,
  "Être un drôle d'oiseau": true,
  "Être tout feu tout flamme": true,
  "Être tombé sur la tête": true,
  "Être sur les dents": true,
  "Être sur le gril": true,
  "Être sur la paille": true,
  "Être sous les projecteurs": true,
  "Être serrés comme des sardines": true,
  "Être rusé comme un renard": true,
  "Être réglé comme du papier à musique": true,
  "Être pris à la gorge": true,
  "Être pourri jusqu'à la moelle": true,
  "Être plein comme un œuf": true,
  "Être pendu aux lèvres de quelqu'un": true,
  "Être pédé comme un foc/phoque": true,
  "Être parti pour durer": true,
  "Être noir (ivre)": true,
  "Être myope comme une taupe": true,
  "Être mordu de quelque chose": true,
  "Être lessivé": true,
  "Être léger comme une plume": true,
  "Être le dos au mur": true,
  "Être le dindon de la farce": true,
  "Être le bec dans l'eau": true,
  "Être la cinquième roue du carrosse": true,
  "Être en reste": true,
  "Être de mauvais poil": true,
  "Être dans les starting blocks": true,
  "Être dans les bras de Morphée": true,
  "Être dans le rouge": true,
  "Être dans le pétrin": true,
  "Être dans le collimateur": true,
  "Être dans la merde jusqu'au cou": true,
  "Être dans la lune": true,
  "Être dans de beaux draps": true,
  "Être copains comme cochons": true,
  "Être connu comme le loup blanc": true,
  "Être comme deux ronds de flan": true,
  "Être bête comme ses pieds": true,
  "Être aux anges": true,
  "Être au trente-sixième dessous": true,
  "Être au tapis": true,
  "Être au four et au moulin": true,
  "Être à tu et à toi": true,
  "Être à poil": true,
  "Être à pied d'œuvre": true,
  "Être à l'index": true,
  "Être à la masse": true,
  "Être à cheval sur les principes": true,
  "Être à bout de nerfs": true,
  "Être à bout": true,
  "Étouffer dans l'œuf": true,
  "Etna": true,
  "Éthanol": true,
  "Éteindre": true,
  "Étang": true,
  "Et pour quelques dollars de plus": true,
  "Et Dieu… créa la femme": true,
  "Et au milieu coule une rivière": true,
  "Esturgeon": true,
  "Essai": true,
  "Esprits criminels": true,
  "Espadrilles": true,
  "Espace Schengen": true,
  "Escabeau": true,
  "Érosion": true,
  "Erin Brockovich": true,
  "Éric Cantona": true,
  "Équipe de France de football": true,
  "Équipe": true,
  "Équinoxe": true,
  "Équation": true,
  "Épluchure": true,
  "Épistémologie": true,
  "Épingle à nourrice": true,
  "Épilepsie": true,
  "Épicure": true,
  "Épice": true,
  "Épée de Damoclès": true,
  "Épater la galerie": true,
  "Envoyer valser": true,
  "Envoyer sur les roses": true,
  "Envoyer chier": true,
  "Envoyé spécial": true,
  "Environnement": true,
  "Entretien avec un vampire": true,
  "Entrer en lice": true,
  "Entrer dans le vif du sujet": true,
  "Entrer dans le moule": true,
  "Entrer dans le jeu de quelqu'un": true,
  "Entreprise": true,
  "Entre quatre yeux": true,
  "Entre la poire et le fromage": true,
  "Entre chien et loup": true,
  "Entorse": true,
  "Engueuler comme du poisson pourri": true,
  "Enfiler les perles": true,
  "Enfant de chœur": true,
  "Énergie solaire": true,
  "Énergie renouvelable": true,
  "Énergie": true,
  "Enculer les mouches": true,
  "Encre de Chine": true,
  "ENA": true,
  "En voir des vertes et des pas mûres": true,
  "En voir de toutes les couleurs": true,
  "En venir aux mains": true,
  "En un tournemain": true,
  "En un clin d'œil": true,
  "En roue libre": true,
  "En prendre plein la figure": true,
  "En prendre de la graine": true,
  "En pleine poire": true,
  "En perdre son latin": true,
  "En mettre sa main au feu": true,
  "En mettre sa main à couper": true,
  "En garder sous le coude": true,
  "En faire toute une montagne": true,
  "En faire tout un plat": true,
  "En faire tout un fromage": true,
  "En faire des tonnes": true,
  "En double file": true,
  "En deux temps trois mouvements": true,
  "En boucher un coin": true,
  "En avril, ne te découvre pas d’un fil, en mai, fais ce qu’il te plaît": true,
  "En avoir ras le bol": true,
  "En avoir gros sur la patate": true,
  "En attendant Godot": true,
  "Empreinte digitale": true,
  "Empirisme": true,
  "Empire romain": true,
  "Empire ottoman": true,
  "Empathie": true,
  "Émoticône / Smiley": true,
  "Emmanuelle": true,
  "Emmanuel Kant": true,
  "Eminem": true,
  "Émile Zola": true,
  "Émile Louis": true,
  "Elvis Presley": true,
  "Elton John": true,
  "Elsa d'Arendelle": true,
  "Ellen Ripley": true,
  "Elle a les yeux révolver": true,
  "Élisabeth II": true,
  "Élève": true,
  "Elephant Man": true,
  "Éléphant de mer": true,
  "Électron": true,
  "Électricien": true,
  "Élections législatives": true,
  "Élection présidentielle": true,
  "Eldorado": true,
  "Élan": true,
  "El Niño": true,
  "Éjaculation": true,
  "Effort": true,
  "Effet secondaire": true,
  "Effet de serre": true,
  "Edward Snowden": true,
  "Edward aux mains d'argent": true,
  "Edouard Philippe": true,
  "Édith Piaf": true,
  "Edgar Allan Poe": true,
  "EDF (Électricité de France)": true,
  "Ed Wood": true,
  "Écrire noir sur blanc": true,
  "Écrire en patte de mouche": true,
  "Écouter aux portes": true,
  "Écosystème": true,
  "Écosse": true,
  "Écorce": true,
  "Économie": true,
  "École Polytechnique": true,
  "École Normale Supérieure": true,
  "Éclairer sa lanterne": true,
  "Éclair": true,
  "Éclabousser": true,
  "Échographie": true,
  "ECG (Electrocardiogramme)": true,
  "Écarter les cuisses": true,
  "Écart type": true,
  "Eau minérale": true,
  "Eau gazeuse": true,
  "Eau douce": true,
  "Eau de Javel": true,
  "E.T. l'extra-terrestre": true,
  "E.Leclerc": true,
  "Dystopie": true,
  "Durer trois plombes": true,
  "Dur de la feuille": true,
  "Dur à cuire": true,
  "Dupond et Dupont": true,
  "Dune": true,
  "Dubaï": true,
  "Du silence et des ombres": true,
  "Droopy": true,
  "Droit de vote": true,
  "Droit comme un i": true,
  "Droit": true,
  "Drogue": true,
  "Drapeau": true,
  "Dragon de Komodo": true,
  "Dr House": true,
  "Downton Abbey": true,
  "Douze hommes en colère": true,
  "Douze Apôtres": true,
  "Doux comme un agneau": true,
  "Double Impact": true,
  "Dossier": true,
  "Dormir sur ses deux oreilles": true,
  "Dormir en chien de fusil": true,
  "Dormir comme un loir": true,
  "Dormir à poings fermés": true,
  "Doom": true,
  "Donner un os à ronger": true,
  "Donner sa langue au chat": true,
  "Donner le feu vert": true,
  "Donner le bon Dieu sans confession": true,
  "Donner du fil à retordre": true,
  "Donner des ailes": true,
  "Donner c’est donner, reprendre c’est voler": true,
  "Donnant donnant": true,
  "Don Quichotte": true,
  "Don Camillo": true,
  "Dominer de la tête et des épaules": true,
  "Docteur Quinn, femme médecin": true,
  "Docteur Jekyll et Mr. Hyde": true,
  "Docteur Folamour": true,
  "Dobermann": true,
  "Djembé": true,
  "Django Unchained": true,
  "Dix petits nègres": true,
  "Divorcer": true,
  "Divergente": true,
  "District 9": true,
  "Disneyland": true,
  "Dirty Dancing": true,
  "Dire ses quatre vérités à quelqu'un": true,
  "Dire ce qu'on a sur le cœur": true,
  "Dioxyde de carbone": true,
  "Dinde": true,
  "Dimanche": true,
  "Dieu merci": true,
  "Diego Maradona": true,
  "Didgeridoo": true,
  "Diapason": true,
  "Diamants sur canapé": true,
  "DHL": true,
  "Dexter": true,
  "Dévorer des yeux": true,
  "Devoir une fière chandelle": true,
  "Devenir chèvre": true,
  "Développement durable": true,
  "Deux poids, deux mesures": true,
  "Détournement de fonds": true,
  "Déterrer la hache de guerre": true,
  "Détective privé": true,
  "Dessin": true,
  "Desperate Housewives": true,
  "Désobéir": true,
  "Déshabiller Pierre pour habiller Paul": true,
  "Des sueurs froides": true,
  "Des souris et des hommes": true,
  "Des lettres de noblesse": true,
  "Des larmes de crocodiles": true,
  "Des clous !": true,
  "Des chiffres et des lettres": true,
  "Des bas": true,
  "Député": true,
  "Déposer les armes": true,
  "Dépasser les bornes": true,
  "Denver, le dernier dinosaure": true,
  "Démouler un cake": true,
  "Démocratie": true,
  "Démarrer sur les chapeaux de roues": true,
  "Démarrer au quart de tour": true,
  "Demain on rase gratis": true,
  "Demain ne meurt jamais": true,
  "Demain": true,
  "Délit d'initié": true,
  "Delicatessen": true,
  "Déforestation": true,
  "Défiler à la queue leu-leu": true,
  "Déesse": true,
  "Décrocher la timballe": true,
  "Découvrir le pot aux roses": true,
  "Débardeur": true,
  "De mauvais goût": true,
  "De l'argent sale": true,
  "De la poudre aux yeux": true,
  "De la tête aux pieds": true,
  "De la confiture pour les cochons": true,
  "De derrière les fagots": true,
  "De Charybde en Scylla": true,
  "De Cape et de Crocs": true,
  "De but en blanc": true,
  "De bonne guerre": true,
  "De battre mon cœur s'est arrêté": true,
  "Day of the Tentacle": true,
  "David Guetta": true,
  "David Bowie": true,
  "David Beckham": true,
  "D'Artagnan": true,
  "Dark Crystal": true,
  "Dante Alighieri": true,
  "Danse avec les loups": true,
  "Danse": true,
  "Dans l'œil du cyclone": true,
  "Dans les bras de Morphée": true,
  "Dans le mille": true,
  "Dans la peau de John Malkovich": true,
  "Danone": true,
  "Daniel Balavoine": true,
  "Danemark": true,
  "Dana Scully": true,
  "Damer le pion": true,
  "Dame": true,
  "Daltonisme": true,
  "Dallas": true,
  "Dalida": true,
  "Dahu": true,
  "Daft Punk": true,
  "Daffy Duck": true,
  "Dada / Dadaïsme": true,
  "Da Vinci Code": true,
  "Cyrano de Bergerac": true,
  "Cycle de l'eau": true,
  "Cunnilingus": true,
  "Culture": true,
  "Cuivre": true,
  "Cuir": true,
  "Cubisme": true,
  "Cuba": true,
  "Crotte": true,
  "Croquer": true,
  "Croix-Rouge": true,
  "Croisades": true,
  "Croire dur comme fer": true,
  "Crocodile Dundee": true,
  "Croc-Blanc": true,
  "Cristiano Ronaldo": true,
  "Crise des missiles de Cuba": true,
  "Criquet": true,
  "Crime et Châtiment": true,
  "Crime contre l'humanité": true,
  "Crier sur les toits": true,
  "Crier comme un putois": true,
  "Crier au loup": true,
  "Cricket": true,
  "Crever la dalle": true,
  "Crème fraîche": true,
  "Créer de toutes pièces": true,
  "Cracher ses poumons": true,
  "Cracher dans la soupe": true,
  "Cracher au bassinet": true,
  "Couverture de survie": true,
  "Couture": true,
  "Coûter un bras": true,
  "TF1": true,
  "Mahomet": true,
  "Le Coran": true,
  "Couper les cheveux en quatre": true,
  "Coup d'œil": true,
  "Coup d'éclat": true,
  "Coup de Trafalgar": true,
  "Coup de pouce": true,
  "Coup de pied de l'âne": true,
  "Coup de main": true,
  "Coup de Jarnac": true,
  "Coup de foudre à Notting Hill": true,
  "Coup de cœur": true,
  "Couleuvre": true,
  "Couleur locale": true,
  "Couleur": true,
  "Coucou": true,
  "Côte d'Ivoire": true,
  "Cosmocats": true,
  "Corto Maltese": true,
  "Corse": true,
  "Corne d'abondance": true,
  "Cordilière des Andes": true,
  "Cor de chasse": true,
  "Continent": true,
  "Conter fleurette": true,
  "Conte": true,
  "Constellation": true,
  "Constantinople": true,
  "Connu comme le loup blanc": true,
  "Connaître la musique": true,
  "Connais-toi toi-même": true,
  "Conjugaison": true,
  "Confucius": true,
  "Confucianisme": true,
  "Confit de canard": true,
  "Confession": true,
  "Concorde": true,
  "Concordat": true,
  "Conan le Barbare": true,
  "Comptine": true,
  "Complexe d'Œdipe": true,
  "Communication": true,
  "Commune de Paris": true,
  "Commedia dell'arte": true,
  "Comme un poisson dans l'eau": true,
  "Comme un éléphant dans un magasin de porcelaine": true,
  "Comme un coq en pâte": true,
  "Comme on fait son lit, on se couche": true,
  "Comme chien et chat": true,
  "Comédien": true,
  "Comédie musicale": true,
  "Comédie": true,
  "Combustion": true,
  "Colza": true,
  "Colvert": true,
  "Columbo": true,
  "Colonie de vacances": true,
  "Colombie": true,
  "Colombe": true,
  "Coller aux basques": true,
  "Colisée": true,
  "Colgate": true,
  "Colère": true,
  "Col en V": true,
  "Col": true,
  "Coin": true,
  "Coiffer au poteau": true,
  "Cœur de pierre": true,
  "Cœur d'artichaut": true,
  "Code Quantum": true,
  "Coco Lapin": true,
  "Coco Chanel": true,
  "Clouer le bec": true,
  "Closer": true,
  "Clopin-clopant": true,
  "Clitoris": true,
  "Clint Eastwood": true,
  "Clin d’œil": true,
  "Clé anglaise": true,
  "Clavecin": true,
  "Claustrophobie": true,
  "Claude Monet": true,
  "Claude Lelouch": true,
  "Claude François": true,
  "Classe": true,
  "Claquer les dents": true,
  "Citizen Kane": true,
  "Cité Interdite de Beijing": true,
  "Cirer les bottes de quelqu'un": true,
  "Circulez y'a rien à voir": true,
  "Circoncision": true,
  "Cinquième roue du carrosse": true,
  "Cinquième République": true,
  "Cinquante nuances de Grey": true,
  "Chose promise, chose due": true,
  "Chômeur": true,
  "Chirurgie esthétique": true,
  "Chine": true,
  "Chimiothérapie": true,
  "Chili": true,
  "Chiisme": true,
  "Chiffres romains": true,
  "Chiffre": true,
  "Chier une pendule": true,
  "Chicken Run": true,
  "Chicago": true,
  "Cheval de bataille": true,
  "Chérie, j'ai rétréci les gosses": true,
  "Chercher midi à quatorze heures": true,
  "Chercher la petite bête": true,
  "Chercher des poux": true,
  "Chercher": true,
  "Chêne": true,
  "Che Guevara": true,
  "Chaud": true,
  "Château de Versailles": true,
  "Château de Chambord": true,
  "Chat siamois": true,
  "Chat sauvage": true,
  "Chat échaudé craint l’eau froide": true,
  "Chat angora": true,
  "Chassez le naturel, il revient au galop": true,
  "Charmed": true,
  "Charlot": true,
  "Charlie et la Chocolaterie": true,
  "Charles Manson": true,
  "Charles de Gaulle": true,
  "Charles Darwin": true,
  "Charles Baudelaire": true,
  "Charité bien ordonnée commence par soi-même": true,
  "Charger la barque": true,
  "Charbon": true,
  "Chapi Chapo": true,
  "Chapeau melon et bottes de cuir": true,
  "Chapeau bas !": true,
  "Chantons sous la pluie": true,
  "Chanter comme une casserole": true,
  "Chanter à tue-tête": true,
  "Changer son fusil d'épaule": true,
  "Champ magnétique": true,
  "Chamois": true,
  "Châle": true,
  "Chaîne de montagnes": true,
  "Chaîne alimentaire": true,
  "Chacun voit midi à sa porte": true,
  "Chacun son métier, les vaches seront bien gardées": true,
  "Chacun pour soi, Dieu pour tous": true,
  "Chacal": true,
  "C'est râpé !": true,
  "C'est pas sorcier": true,
  "C'est pas du jeu": true,
  "C'est pas demain la veille": true,
  "C'est la mère Michel": true,
  "C'est du chinois !": true,
  "C'est beaucoup dire": true,
  "C'est arrivé près de chez vous": true,
  "Césarienne": true,
  "Certains l'aiment chaud": true,
  "Centre-ville": true,
  "Centre Georges-Pompidou (Beaubourg)": true,
  "Celtes": true,
  "Céline Dion": true,
  "Ceinture d'astéroïdes": true,
  "Ce qui ne tue pas rend plus fort": true,
  "CD": true,
  "Cauchemar": true,
  "Caucase": true,
  "Catholicisme": true,
  "Catherine Deneuve": true,
  "Cathédrale Notre-Dame de Paris": true,
  "Cathédrale": true,
  "Catalogue": true,
  "Cassoulet": true,
  "Casser sa pipe": true,
  "Casser les pieds": true,
  "Casser les couilles": true,
  "Casser la croûte": true,
  "Casse-noisette": true,
  "Casper le fantôme": true,
  "Casino Royale": true,
  "Casablanca": true,
  "Carte Vitale": true,
  "Carte mère": true,
  "Cars": true,
  "Carrefour": true,
  "Carreau": true,
  "Carpe diem": true,
  "Carpe": true,
  "Carnivore": true,
  "Carnet": true,
  "Carnaval de Rio": true,
  "Carmen": true,
  "Carlos": true,
  "Carl Friedrich Gauss": true,
  "Carie (dentaire)": true,
  "Caresser": true,
  "Carême": true,
  "Caraïbes": true,
  "Caractère de cochon": true,
  "Cap-Vert": true,
  "Captain America": true,
  "Caprice": true,
  "Capote anglaise": true,
  "Capoeira": true,
  "Capitalisme": true,
  "Capitaine Jack Sparrow": true,
  "Capitaine Flam": true,
  "Caniveau": true,
  "Candy": true,
  "Candide": true,
  "Cancer": true,
  "Canard boiteux": true,
  "Canal Plus": true,
  "Canal de Panama": true,
  "Canada": true,
  "Campagne": true,
  "Camisole de force": true,
  "Camille Pissarro": true,
  "Camille Claudel": true,
  "Cameroun": true,
  "Camarade": true,
  "Calvin": true,
  "Caliméro": true,
  "Californie": true,
  "Calendrier républicain": true,
  "Calamity Jane": true,
  "Caisse d'épargne": true,
  "CAC 40": true,
  "Ça ne mange pas de pain": true,
  "Ça crève les yeux !": true,
  "Ça craint !": true,
  "Ça baigne !": true,
  "Ça": true,
  "C-3PO": true,
  "C’est le bouquet !": true,
  "C’est l’occasion qui fait le larron": true,
  "C’est l’hôpital qui se moque de la charité": true,
  "C’est en forgeant qu’on devient forgeron": true,
  "C’est dans les vieux pots qu’on fait la meilleure soupe": true,
  "C’est au pied du mur qu’on voit le maçon": true,
  "Bulldog": true,
  "Chutes du Niagara": true,
  "Christopher Nolan": true,
  "Câlin": true,
  "Buffy contre les vampires": true,
  "Buckingham Palace": true,
  "Bruxelles": true,
  "Brun": true,
  "Brûler la chandelle par les deux bouts": true,
  "Bruce Willis": true,
  "Bruce Lee": true,
  "Broyer du noir": true,
  "Brouillard": true,
  "Brochet": true,
  "Brocante": true,
  "British Museum": true,
  "Briser la glace": true,
  "Brise-glace": true,
  "Briller par son absence": true,
  "Brigitte Lahaie": true,
  "Bridget Jones": true,
  "Bricoler": true,
  "Bretelles": true,
  "Bretagne": true,
  "Brésil": true,
  "Brebis": true,
  "Breaking Bad": true,
  "Brazil": true,
  "Braveheart": true,
  "Brasilia": true,
  "Branler": true,
  "Brad Pitt": true,
  "Bousculer": true,
  "Bourriquet": true,
  "Bourgeon": true,
  "Boumbo": true,
  "Boulier": true,
  "Boule et Bill": true,
  "Bouillabaisse": true,
  "Bouddhisme": true,
  "Bouche à oreille": true,
  "Bouc émissaire": true,
  "Bouc": true,
  "Bouba": true,
  "Bottes de sept lieues": true,
  "Botter en touche": true,
  "Bordeaux": true,
  "Bons baisers de Russie": true,
  "Bonobo": true,
  "Bonnie et Clyde": true,
  "Bonnet de nuit": true,
  "Bonne nuit les petits": true,
  "Bonheur": true,
  "Bondir": true,
  "Bon sang !": true,
  "Bon sang ne saurait mentir": true,
  "Bon enfant": true,
  "Boîte de Pandore": true,
  "Boîte": true,
  "Boire la tasse": true,
  "Boire du petit-lait": true,
  "Bohemian Rhapsody": true,
  "Bœuf bourguignon": true,
  "Bobsleigh": true,
  "Bob Dylan": true,
  "BNP Paribas": true,
  "Bluetooth": true,
  "Blues": true,
  "Blueberry": true,
  "Blouse": true,
  "Blood Diamond": true,
  "Blond": true,
  "Blanchir de l'argent": true,
  "Blanche-Neige et les sept nains": true,
  "Blanc-bec": true,
  "Blanc comme un linge": true,
  "Blanc comme un cachet d'aspirine": true,
  "Blanc comme neige": true,
  "Blanc bonnet et bonnet blanc": true,
  "Blake et Mortimer": true,
  "Blaireau": true,
  "Blade Runner": true,
  "Blacksad": true,
  "Black Swan": true,
  "Black Mirror": true,
  "BlaBlaCar": true,
  "Bitcoin": true,
  "Bip bip": true,
  "Biosphère": true,
  "Biographie": true,
  "Biodiversité": true,
  "Billy the Kid": true,
  "Billy Elliot": true,
  "Billard": true,
  "Bill Gates": true,
  "Big data": true,
  "Big Bang": true,
  "Bienvenue chez les Ch'tis": true,
  "Bienvenue à Gattaca": true,
  "Bien mal acquis ne profite jamais": true,
  "Bien faire, et laisser dire": true,
  "Bicyclette": true,
  "Biche": true,
  "Bicarbonate de sodium": true,
  "Bibifoc": true,
  "Bianca Castafiore": true,
  "BFM TV": true,
  "Beyoncé": true,
  "Bêtise": true,
  "Bête noire": true,
  "Bête du Gévaudan": true,
  "Bête de l'Apocalypse": true,
  "Bête comme ses pieds": true,
  "Bête comme chou": true,
  "Bête": true,
  "Betadine": true,
  "Bernard-Henri Lévy": true,
  "Bernard Tapie": true,
  "Bernard l'hermite": true,
  "Bernard Arnault": true,
  "Berlin": true,
  "Berbères": true,
  "Benjamin Franklin": true,
  "Benito Mussolini": true,
  "Ben-Hur": true,
  "Belle et Sébastien": true,
  "Belle de jour": true,
  "Belgique": true,
  "Belette": true,
  "Beijing (Pékin)": true,
  "Bec Bunsen": true,
  "Beaucoup de bruit pour rien": true,
  "Beau comme un dieu": true,
  "Beau comme un camion": true,
  "Bayer aux corneilles": true,
  "Bavard comme une pie": true,
  "Baume à lèvres": true,
  "Battre la chamade": true,
  "Battre la campagne": true,
  "Battre en retraite": true,
  "Battre en brèche": true,
  "Battre à plate couture": true,
  "Batteur à œufs": true,
  "Bâtir des châteaux en Espagne": true,
  "Bataille navale": true,
  "Bataille de Verdun": true,
  "Basilique du Sacré-Cœur": true,
  "Basilic": true,
  "Basic Instinct": true,
  "Base de données": true,
  "Barreau": true,
  "Barracuda": true,
  "Baroque": true,
  "Barney Stinson": true,
  "Barcelone": true,
  "Baptême": true,
  "Bangladesh": true,
  "Bande originale": true,
  "Baloo": true,
  "Boxer": true,
  "Christianisme orthodoxe": true,
  "Christianisme": true,
  "Christiane Taubira": true,
  "Coûter la peau des fesses": true,
  "Cousu de fil blanc": true,
  "Cousin": true,
  "Course contre la montre": true,
  "Courrier électronique": true,
  "Courir plusieurs lièvres à la fois": true,
  "Courir à perdre haleine": true,
  "Courant continu": true,
  "Cour": true,
  "Coups et blessures": true,
  "Le masque de Toutânkhamon": true,
  "Le Masque de fer": true,
  "Le Manège enchanté": true,
  "Le malheur des uns fait le bonheur des autres": true,
  "Le Malade imaginaire": true,
  "Le Loup de Wall Street": true,
  "UE (Union européenne)": true,
  "Thot": true,
  "Nasique": true,
  "Morse (animal)": true,
  "Médecine": true,
  "Journée internationale des femmes": true,
  "Jolly Jumper": true,
  "Janus": true,
  "Diane": true,
  "Car": true,
  "Au clair de la lune": true,
  "14-Juillet": true,
  "Yangzi Jiang": true,
  "Zone euro": true,
  "Zodiaque": true,
  "Zlatan Ibrahimović": true,
  "Yves Saint Laurent": true,
  "Yougoslavie": true,
  "Yom Kippour": true,
  "Yoko Tsuno": true,
  "Yeux de merlan frit": true,
  "Yesterday": true,
  "Yellow Submarine": true,
  "Y regarder à deux fois": true,
  "Y laisser des plumes": true,
  "Y avoir anguille sous roche": true,
  "Y aller crescendo": true,
  "XV de France": true,
  "XIII": true,
  "Xi Jinping": true,
  "X-Files": true,
  "Xavier Niel": true,
  "World Wide Web": true,
  "Woody Allen": true,
  "Woodstock": true,
  "Wolfgang Amadeus Mozart": true,
  "Wind Of Change": true,
  "Willow": true,
  "Wiki": true,
  "Western": true,
  "West Side Story": true,
  "WC": true,
  "Wayne's World": true,
  "Watergate": true,
  "Watchmen": true,
  "Washington DC": true,
  "Warren Buffett": true,
  "Waka Waka (Time For Africa)": true,
  "Voyage au bout de la nuit": true,
  "Vouloir le beurre, l'argent du beurre et le sourire de la crémière": true,
  "Vouloir le beurre et l'argent du beurre": true,
  "Volver": true,
  "Voltaire": true,
  "Volkswagen": true,
  "Voler de ses propres ailes": true,
  "Voler dans les plumes de quelqu'un": true,
  "Vol au-dessus d'un nid de coucou": true,
  "Voiture sans permis": true,
  "Voir trente-six chandelles": true,
  "Voir rouge": true,
  "Voir midi à sa porte": true,
  "Voir le bout du tunnel": true,
  "Voir des éléphants roses": true,
  "Vivre et laisser mourir": true,
  "Vivre d'amour et d'eau fraîche": true,
  "Vitesse de la lumière": true,
  "Vitesse": true,
  "Virgule": true,
  "Virgin Suicides": true,
  "Virer de bord": true,
  "Virage": true,
  "Vingt mille lieues sous les mers": true,
  "Vincent van Gogh": true,
  "Vieux de la vieille": true,
  "Viêt Nam": true,
  "Vie privée": true,
  "Victor Hugo": true,
  "Victoire à la Pyrrhus": true,
  "Vice-versa": true,
  "Viaduc": true,
  "Vésuve": true,
  "Vésicule biliaire": true,
  "Very Bad Trip": true,
  "Vert de rage": true,
  "Verre doseur": true,
  "Vérité": true,
  "Vénus": true,
  "Ventre affamé n’a pas d’oreilles": true,
  "Vendredi 13": true,
  "Vendre son âme au diable": true,
  "Vendre la peau de l'ours avant de l'avoir tué": true,
  "Vendre (son) père et (sa) mère": true,
  "Veiller au grain": true,
  "Véhicule": true,
  "Vatican": true,
  "Vassily Kandinsky": true,
  "Vasco de Gama": true,
  "Vanessa Paradis": true,
  "Valse": true,
  "Valoir le détour": true,
  "Valérian": true,
  "Vaisselle": true,
  "Utérus": true,
  "Usual Suspects": true,
  "Usine à gaz": true,
  "USB (Universal Serial Bus)": true,
  "Usain Bolt": true,
  "Uranus": true,
  "Université Harvard": true,
  "Union des républiques socialistes soviétiques": true,
  "Une tête de mule": true,
  "Une tête de linotte": true,
  "Une Saison en enfer": true,
  "Une queue de poisson": true,
  "Une poule sur un mur": true,
  "Une personne avertie en vaut deux": true,
  "Une Nuit en enfer": true,
  "Une Marche": true,
  "Une maison de poupée": true,
  "Une main de fer dans un gant de velours": true,
  "Une Journée en enfer": true,
  "Une histoire vraie": true,
  "Une hirondelle ne fait pas le printemps": true,
  "Une fois n’est pas coutume": true,
  "Une chatte n’y retrouverait pas ses petits": true,
  "Underworld": true,
  "Un, deux, trois, nous irons au bois": true,
  "Un tête à queue": true,
  "Un violon sur le toit": true,
  "Un vieillard qui meurt est une bibliothèque qui brûle": true,
  "Un truc de ouf": true,
  "Un tramway nommé Désir": true,
  "Un tissu pied-de-poule": true,
  "Un tiens vaut mieux que deux tu l’auras": true,
  "Un sou est un sou": true,
  "Un short": true,
  "Un poisson nommé Wanda": true,
  "Un petit cochon pendu au plafond": true,
  "Un pas": true,
  "Un œil de lynx": true,
  "Un malheur ne vient jamais seul": true,
  "Un loup dans la bergerie": true,
  "Un Jour sans fin": true,
  "Un jour à marquer d'une pierre blanche": true,
  "Un Homme d'exception": true,
  "Un homme de paille": true,
  "Un gagne-pain": true,
  "Un flic à la maternelle": true,
  "Un éclair de génie": true,
  "Un brouillard à couper au couteau": true,
  "Un autre son de cloche": true,
  "Un Américain à Paris": true,
  "Ulysse 31": true,
  "Ukraine": true,
  "U2": true,
  "U.S. Route 66": true,
  "Twin Peaks": true,
  "Twilight": true,
  "Twelve Years a Slave": true,
  "TVA (Taxe sur la valeur ajoutée)": true,
  "Turquie": true,
  "Tunnel sous la Manche": true,
  "Tunisie": true,
  "Tuer le temps": true,
  "Tuer la poule aux œufs d'or": true,
  "T-shirt": true,
  "Truite": true,
  "True Lies": true,
  "True Detective": true,
  "Trouver chaussure à son pied": true,
  "Tron": true,
  "Trombone à coulisse": true,
  "Troisième République": true,
  "Trois petits chats": true,
  "Trois fois rien": true,
  "Tristan et Iseut": true,
  "Trier sur le volet": true,
  "Trente Glorieuses": true,
  "Treize à la douzaine": true,
  "Travailler d'arrache-pied": true,
  "Travailler au noir": true,
  "Travail de fourmi": true,
  "Transsibérien": true,
  "Tranche": true,
  "Traiter par dessous la jambe": true,
  "Traiter (quelqu'un) comme un chien": true,
  "Trainspotting": true,
  "Traduction": true,
  "Toyota": true,
  "Toute vérité n’est pas bonne à dire": true,
  "Tout vient à point à qui sait attendre": true,
  "Tout travail mérite salaire": true,
  "Tout sur ma mère": true,
  "Tout sucre tout miel": true,
  "Tout nouveau, tout beau": true,
  "Tout feu tout flammes": true,
  "Tout est bon dans le cochon": true,
  "Tout est bien qui finit bien": true,
  "Tout ce qui brille n’est pas or": true,
  "Tout ce que vous avez toujours voulu savoir sur le sexe sans jamais oser le demander": true,
  "Toussaint": true,
  "Tous les goûts sont dans la nature": true,
  "Tous les chemins mènent à Rome": true,
  "Tournoi des Six Nations": true,
  "Tournevis cruciforme": true,
  "Tourner en eau de boudin": true,
  "Tourner sept fois sa langue dans sa bouche": true,
  "Tourner autour du pot": true,
  "Tour de Babel": true,
  "Toulouse": true,
  "Toujours": true,
  "Toucher la corde sensible": true,
  "Toucher du bois": true,
  "Touche pas à mon poste !": true,
  "Total Recall": true,
  "Total": true,
  "Top Gun": true,
  "Top Chef": true,
  "Tony Parker": true,
  "Tomber des nues": true,
  "Tomber de haut": true,
  "Tomber dans le panneau": true,
  "Tomber à pic": true,
  "Tomber à l'eau": true,
  "Tomahawk": true,
  "Tom Sawyer": true,
  "Tom Cruise": true,
  "Tokyo": true,
  "Toge": true,
  "Titeuf": true,
  "Titanic": true,
  "Tissu": true,
  "Tirez sur le pianiste": true,
  "Tirer un trait (sur quelque chose)": true,
  "Tirer son épingle du jeu": true,
  "Tirer sa révérence": true,
  "Tirer les vers du nez": true,
  "Tirer les marrons du feu": true,
  "Tirer le diable par la queue": true,
  "Tirer la couverture à soi": true,
  "Tirer à boulets rouges": true,
  "Tiré par les cheveux": true,
  "Times Square": true,
  "Tim Burton": true,
  "Tilleul": true,
  "Tigre et Dragon": true,
  "Tiger Woods": true,
  "Tige": true,
  "Tic et Tac": true,
  "Thorgal": true,
  "Thomas Piketty": true,
  "Thomas Edison": true,
  "There Will Be Blood": true,
  "Théorie de l'Évolution": true,
  "Thelma et Louise": true,
  "The Wire (Sur écoute)": true,
  "The Wall": true,
  "The Walking Dead": true,
  "The Voice : La Plus Belle Voix": true,
  "The Truman Show": true,
  "The Tree of Life": true,
  "The Thing": true,
  "The Star-Spangled Banner": true,
  "The Social Network": true,
  "The Rolling Stones": true,
  "The Rocky Horror Picture Show": true,
  "The Revenant": true,
  "The Office": true,
  "The Mask": true,
  "The Island": true,
  "The Grand Budapest Hotel": true,
  "The Doors": true,
  "The Dark Knight": true,
  "The Crow": true,
  "The Big Lebowski": true,
  "The Big Bang Theory": true,
  "The Beatles": true,
  "The Artist": true,
  "Thalassa": true,
  "Thaïlande": true,
  "TGV (Train à grande vitesse)": true,
  "Texto": true,
  "Têtu comme un mule": true,
  "Tête de linotte": true,
  "Test": true,
  "Terrier": true,
  "Terreur": true,
  "Terre à terre": true,
  "Tenir sa langue": true,
  "Tenir parole": true,
  "Tenir le bon bout": true,
  "Tenir la chandelle": true,
  "Tendre l'oreille": true,
  "Tendre la main": true,
  "Temps de chien": true,
  "Téléphone fixe": true,
  "Téléchat": true,
  "Tel père, tel fils": true,
  "Tel est pris qui croyait prendre": true,
  "Teddy Riner": true,
  "Tectonique des plaques": true,
  "Taxi Driver": true,
  "Tâter le terrain": true,
  "Tapis roulant": true,
  "Taon": true,
  "Taoïsme": true,
  "Tant va la cruche à l’eau qu’à la fin elle se casse": true,
  "Tant qu’il y a de la vie, il y a de l’espoir": true,
  "Tango": true,
  "Tampon": true,
  "Taille": true,
  "Tai-chi-chuan": true,
  "Tahiti": true,
  "Tableau périodique des éléments": true,
  "Syndrome de Stockholm": true,
  "Svastika": true,
  "Sutter Island": true,
  "Survêtement": true,
  "Surréalisme": true,
  "Sur le pont d'Avignon": true,
  "Sur le pied de guerre": true,
  "Sur le bout de la langue": true,
  "Supernova": true,
  "Superdupont": true,
  "Super Mario Bros.": true,
  "Super Bowl": true,
  "Sunnisme": true,
  "Suisse": true,
  "Sueurs froides": true,
  "Suer sang et eau": true,
  "Suède": true,
  "Sucrer les fraises": true,
  "Stromae": true,
  "Street Fighter II": true,
  "Strasbourg": true,
  "Steven Spielberg": true,
  "Stephen Hawking": true,
  "Statue du Christ Rédempteur (Rio de Janeiro)": true,
  "Startup": true,
  "Starsky et Hutch": true,
  "Starbucks": true,
  "Star Academy": true,
  "Stanley Kubrick": true,
  "Stan Lee": true,
  "Stade de France": true,
  "Sport": true,
  "Spinal Tap": true,
  "Speedy Gonzales": true,
  "Spartacus": true,
  "Space Cowboys": true,
  "Souviens toi… l'été dernier": true,
  "Soutane": true,
  "Sourire jusqu'aux oreilles": true,
  "Sourire": true,
  "Soudan": true,
  "SOS Fantômes": true,
  "Sortir par la grande porte": true,
  "Sortir le grand jeu": true,
  "Sortir du chapeau": true,
  "Sortir des sentiers battus": true,
  "Sonner le glas": true,
  "Son sang ne fit qu'un tour": true,
  "Sommet": true,
  "Sommelier": true,
  "Sommeil": true,
  "Solstice": true,
  "Soleil vert": true,
  "Sole": true,
  "Sol": true,
  "Soja": true,
  "Soir": true,
  "Soif": true,
  "Soie": true,
  "Sœur": true,
  "Socrate": true,
  "Sociologie": true,
  "Société générale": true,
  "Société anonyme": true,
  "Socialisme": true,
  "Snoopy": true,
  "SNCF (Société nationale des chemins de fer français)": true,
  "Slumdog Millionaire": true,
  "Sixième sens": true,
  "Six Feet Under (Six pieds sous terre)": true,
  "Sitôt dit, sitôt fait": true,
  "Sister Act": true,
  "Sissi": true,
  "Sin City": true,
  "Simplet": true,
  "Simone Veil": true,
  "Simone de Beauvoir": true,
  "Sim City": true,
  "Silex": true,
  "Signer son arrêt de mort": true,
  "Signé Cat's Eye": true,
  "Sigmund Freud": true,
  "Siècle des Lumières": true,
  "Si jeunesse savait, si vieillesse pouvait": true,
  "Shinzō Abe": true,
  "Shining": true,
  "Shere Khan": true,
  "Sharon Stone": true,
  "Shakira": true,
  "Sexe, mensonge et vidéo": true,
  "Sexe": true,
  "Seul sur Mars": true,
  "S'étaler de tout son long": true,
  "Serrés comme des sardines": true,
  "Serment d'Hippocrate": true,
  "Sérieux comme un pape": true,
  "Série télévisée": true,
  "Sergio Leone": true,
  "Serge Gainsbourg": true,
  "Serena Williams": true,
  "Séquoia": true,
  "Septième ciel": true,
  "Sept merveilles du monde": true,
  "Sept ans de réflexion": true,
  "Sept ans au tibet": true,
  "Séparer le bon grain de l'ivraie": true,
  "Séparation des Églises et de l'État": true,
  "S'envoyer des fleurs": true,
  "Sentir le fauve": true,
  "Sens interdit": true,
  "Sens dessus dessous": true,
  "S'ennuyer comme un rat mort": true,
  "Sénégal": true,
  "S'en soucier comme de l'an quarante": true,
  "S'en prendre plein la gueule": true,
  "S'en mordre les doigts": true,
  "S'en laver les mains": true,
  "Semaine aux quatre jeudis": true,
  "Semaine": true,
  "Seine": true,
  "Ségolène Royal": true,
  "Sécurité sociale": true,
  "Secret Story": true,
  "Secret de Polichinelle": true,
  "Seconde": true,
  "Sébastien Chabal": true,
  "Se7en": true,
  "Se voir comme le nez au milieu de la figure": true,
  "Se tourner les pouces": true,
  "Se tenir à carreau": true,
  "Se tailler la part du lion": true,
  "Se serrer les coudes": true,
  "Se serrer la ceinture": true,
  "Se retrousser les manches": true,
  "Se ressembler comme deux gouttes d'eau": true,
  "Se reposer sur ses lauriers": true,
  "Se regarder en chiens de faïence": true,
  "Se prendre les pieds dans le tapis": true,
  "Se prendre la tête": true,
  "Se porter comme un charme": true,
  "Se mettre sur son trente-et-un": true,
  "Se mettre la rate au court-bouillon": true,
  "Se mettre en quatre": true,
  "Se mettre au vert": true,
  "Se mettre au diapason": true,
  "Se mettre à table": true,
  "Se mélanger les pinceaux": true,
  "Se méfier de l'eau qui dort": true,
  "Se lever du pied gauche": true,
  "Se lever": true,
  "Se lécher les babines": true,
  "Se laisser marcher sur les pieds": true,
  "Se jeter dans la mêlée": true,
  "Se jeter dans la gueule du loup": true,
  "Se fourrer le doigt dans l'œil jusqu'au coude": true,
  "Se fermer comme une huître": true,
  "Se fendre la poire": true,
  "Se faire rouler dans la farine": true,
  "Se faire un sang d'encre": true,
  "Se faire tout petit": true,
  "Se faire pécho": true,
  "Se faire des nœuds au cerveau": true,
  "Se faire des cheveux blancs": true,
  "Se faire avoir dans les grandes largeurs": true,
  "Se faire avoir comme un bleu": true,
  "Se donner à fond": true,
  "Se défendre bec et ongle": true,
  "Se croire sorti de la cuisse de Jupiter": true,
  "Se creuser la tête": true,
  "Se battre comme des chiffonniers": true,
  "Scrubs": true,
  "Scream": true,
  "Scrabble": true,
  "Scier la branche sur laquelle on est assis": true,
  "Sciences Po": true,
  "Science-Fiction": true,
  "Scènes de ménages": true,
  "Scarlett Johansson": true,
  "Scarface": true,
  "Sauver les meubles": true,
  "Sauter du coq à l'âne": true,
  "Sauter": true,
  "Saturne": true,
  "Sari": true,
  "Sarah Connor": true,
  "Saoûl comme un polonais": true,
  "Sans queue ni tête": true,
  "Sans foi ni loi": true,
  "Sans avoir l'air d'y toucher": true,
  "Sang froid": true,
  "Salvador Dalí": true,
  "Salsa": true,
  "Salamandre": true,
  "Saison": true,
  "Saisir la balle au bond": true,
  "Saint-Sylvestre": true,
  "Saint-Patrick": true,
  "Saint Augustin": true,
  "Sailor Moon": true,
  "Saigner à blanc": true,
  "Sahara": true,
  "Sagrada Família": true,
  "S'acheter une conduite": true,
  "S'accrocher aux branches": true,
  "Sac en plastique": true,
  "Sac de nœuds": true,
  "Sabots": true,
  "Russie": true,
  "RTL": true,
  "Royaume-Uni": true,
  "Royalisme": true,
  "Roumanie": true,
  "Roulette": true,
  "Rouler sur l'or": true,
  "Rouler sa bosse": true,
  "Rouler des mécaniques": true,
  "Rouleau de printemps": true,
  "Rouleau compresseur": true,
  "Roulade": true,
  "Rouge sang": true,
  "Rouge de colère": true,
  "Rouge comme une pivoine": true,
  "Rouge comme une écrevisse": true,
  "Rouge comme un homard": true,
  "Rose des vents": true,
  "Rosa Parks": true,
  "Ronger son frein": true,
  "Rond comme une queue de pelle": true,
  "Romulus et Rémus": true,
  "Roms": true,
  "Rompre le charme": true,
  "Roméo et Juliette": true,
  "Roméo + Juliette": true,
  "Rome ne s’est pas faite en un jour": true,
  "Romantisme": true,
  "Roman épistolaire": true,
  "Roman de gare": true,
  "Rolls Royce": true,
  "Rolland Garros": true,
  "Roger Federer": true,
  "Rococo": true,
  "Rocky": true,
  "Rock Around the Clock": true,
  "Rock": true,
  "Rocher": true,
  "RoboCop": true,
  "Robin des bois": true,
  "Robert Downey Jr.": true,
  "Robert De Niro": true,
  "Robe de chambre": true,
  "Rire jaune": true,
  "Rire comme une baleine": true,
  "Rira bien qui rira le dernier": true,
  "Rihanna": true,
  "Rien n'est joué": true,
  "Rien ne sert de courir ; il faut partir à point": true,
  "Rien ne se perd, rien ne se crée, tout se transforme.": true,
  "Rien de nouveau sous le soleil": true,
  "Ridley Scott": true,
  "Riche comme Crésus": true,
  "Richard Wagner": true,
  "Rhône": true,
  "Rhin": true,
  "Rhétorique": true,
  "Rey": true,
  "Révolution russe": true,
  "Révolution industrielle": true,
  "Révolution française": true,
  "Revenons à nos moutons": true,
  "Retourner sa veste": true,
  "Retour vers le futur": true,
  "Rester sur sa faim": true,
  "Rester sur l'estomac": true,
  "Rester les bras croisés": true,
  "Rester en travers de la gorge": true,
  "Ressources humaines": true,
  "Respirer": true,
  "Résistance": true,
  "Réseau social": true,
  "Requiem for a Dream": true,
  "République dominicaine": true,
  "République": true,
  "Reproduction": true,
  "Reprendre du poil de la bête": true,
  "Repartir comme en quarante": true,
  "Renvoyer l'ascenseur": true,
  "Rentrer dans un trou de souris": true,
  "Rentrée": true,
  "René Magritte": true,
  "René Descartes": true,
  "Rendre son tablier": true,
  "Rendre l'âme": true,
  "Rendre gorge": true,
  "Rendre à César ce qui est à César": true,
  "Rencontres du troisième type": true,
  "Renault": true,
  "Renaud": true,
  "Renaissance": true,
  "Remuer le couteau dans la plaie": true,
  "Remporter haut la main": true,
  "Remonter au déluge": true,
  "Rémi sans famille": true,
  "Remettre les pendules à l'heure": true,
  "Remettre les compteurs à zéro": true,
  "Remettre à demain ce qu'on peut faire le jour même": true,
  "Remède de cheval": true,
  "Regarder d'un œil neuf": true,
  "Regarder d'un œil complaisant": true,
  "Regarder dans le blanc des yeux": true,
  "Réflexe": true,
  "Réfléchir à deux fois": true,
  "Récréation": true,
  "Récolter ce que l'on a semé": true,
  "Réchauffement climatique": true,
  "Recep Tayyip Erdoğan": true,
  "Real Madrid": true,
  "Rayons X": true,
  "Rayon": true,
  "Ray Ban": true,
  "Ravaler la façade": true,
  "Rate": true,
  "Rasta Rocket": true,
  "Rasoir d'Ockham": true,
  "Râpe": true,
  "Rango": true,
  "Ramsès II": true,
  "Rampe": true,
  "Ramener sa fraise": true,
  "Ramasser à la petite cuiller": true,
  "Rain Man": true,
  "Raie manta": true,
  "Rafael Nadal": true,
  "Radiographie": true,
  "Raconter des salades": true,
  "Raconter des craques": true,
  "Quotient intellectuel": true,
  "Quiche lorraine": true,
  "Qui vole un œuf vole un bœuf": true,
  "Qui vivra verra": true,
  "Qui vit par l’épée périra par l’épée": true,
  "Qui veut voyager loin ménage sa monture": true,
  "Qui veut noyer son chien l’accuse de la rage": true,
  "Qui veut la peau de Roger Rabbit ?": true,
  "Qui veut la paix, prépare la guerre": true,
  "Qui veut la fin veut les moyens": true,
  "Qui veut gagner des millions ?": true,
  "Qui va à la chasse perd sa place": true,
  "Qui trop embrasse, mal étreint": true,
  "Qui sème le vent, récolte la tempête": true,
  "Qui se ressemble s’assemble": true,
  "Qui s’y frotte s’y pique": true,
  "Qui peut le plus, peut le moins": true,
  "Qui ne risque rien n’a rien": true,
  "Qui ne dit mot consent": true,
  "Qui m’aime me suive": true,
  "Qui dort dîne": true,
  "Qui aime bien, châtie bien": true,
  "Qui a bu boira": true,
  "Question": true,
  "Qu'est-ce qu'on a fait au bon Dieu ?": true,
  "Quentin Tarantino": true,
  "Quelle mouche l'a piqué ?": true,
  "Québec": true,
  "Quatre mariages et un enterrement": true,
  "Quand on parle du loup, on en voit la queue": true,
  "Quand les poules auront des dents": true,
  "Quand le vin est tiré, il faut le boire": true,
  "Quand le chat n’est pas là, les souris dansent": true,
  "Quand Harry rencontre Sally": true,
  "Quai": true,
  "Qatar": true,
  "Pythagore": true,
  "Pyrénées": true,
  "Pyramide de Khéops": true,
  "Puma": true,
  "Pulp Fiction": true,
  "Psychose": true,
  "Psychologie": true,
  "Psychiatre": true,
  "PSG (Paris Saint-Germain)": true,
  "Prudence est mère de sûreté": true,
  "Proverbe": true,
  "Protestantisme": true,
  "Promettre monts et merveilles": true,
  "Promesse": true,
  "Promenons-nous dans les bois": true,
  "Procrastination": true,
  "Probabilité": true,
  "Prison Break": true,
  "Prise de tête": true,
  "Prince of Persia": true,
  "Preuve par neuf": true,
  "Pretty Woman": true,
  "Prêter main forte": true,
  "Président de la République": true,
  "Prendre sous son aile": true,
  "Prendre son temps": true,
  "Prendre son pied": true,
  "Prendre son mal en patience": true,
  "Prendre son courage à deux mains": true,
  "Prendre ses jambes à son cou": true,
  "Prendre racine": true,
  "Prendre pour argent comptant": true,
  "Prendre le taureau par les cornes": true,
  "Prendre la tangente": true,
  "Prendre la poudre d'escampette": true,
  "Prendre la mouche": true,
  "Prendre du galon": true,
  "Prendre du bon temps": true,
  "Prendre des mesures": true,
  "Prendre des gants": true,
  "Prendre à la légère": true,
  "Prendre à cœur": true,
  "Premiers secours": true,
  "Premier ministre": true,
  "Premier contact": true,
  "Préhistoire": true,
  "Predator": true,
  "Préau": true,
  "Power Rangers": true,
  "Pousser le bouchon (un peu trop loin)": true,
  "Pousser des cris d'orfraie": true,
  "Pousser comme des champignons": true,
  "Poussée d'Archimède": true,
  "Pourquoi faire simple quand on peut faire compliqué": true,
  "Pourcent": true,
  "Pour des prunes": true,
  "Poupée Vaudou": true,
  "Poule mouillée": true,
  "Poser un lapin": true,
  "Portugal": true,
  "Porter le chapeau (la responsabilité)": true,
  "Porte-fenêtre": true,
  "Porsche": true,
  "Porcinet": true,
  "Populisme": true,
  "Pop music": true,
  "Pont suspendu": true,
  "Pompéi": true,
  "Pomme de reinette et pomme d'api": true,
  "Pomme d'Adam": true,
  "Polythéisme": true,
  "Polyphonie corse": true,
  "Poltergeist": true,
  "Pologne": true,
  "Politique de l'autruche": true,
  "Polisse": true,
  "Police Academy": true,
  "Polar": true,
  "Poker Face": true,
  "Poivre et sel": true,
  "Poitrine": true,
  "Points de suspension": true,
  "Point n’est besoin d’espérer pour entreprendre, ni de réussir pour persévérer": true,
  "Point d'interrogation": true,
  "Point d'exclamation": true,
  "Point Break": true,
  "Point": true,
  "Poil de carotte": true,
  "Poésie": true,
  "Pocahontas": true,
  "PME (Petite et moyenne entreprise)": true,
  "Pluto": true,
  "Plus on est de fous, plus on rit": true,
  "Plus belle la vie": true,
  "Pli": true,
  "Pleuvoir des hallebardes": true,
  "Pleuvoir des cordes": true,
  "Pleuvoir comme vache qui pisse": true,
  "Pleurer toutes les larmes de son corps": true,
  "Pleurer comme une Madeleine": true,
  "Pléonasme": true,
  "Plein Soleil": true,
  "Plein les yeux": true,
  "Plein aux as": true,
  "Platon": true,
  "Plateau": true,
  "Plat comme une limande": true,
  "Plat": true,
  "Planeur": true,
  "Planète": true,
  "Plancher des vaches": true,
  "Plan Marshall": true,
  "Plage": true,
  "Plafond": true,
  "Placenta": true,
  "Piton de la Fournaise": true,
  "Pirates des Caraïbes": true,
  "Piquer une tête": true,
  "Pique nique-douille, c'est toi l'andouille": true,
  "Pipi de chat": true,
  "Pink Floyd": true,
  "Pilule contraceptive": true,
  "Pif le chien": true,
  "Pierrot le fou": true,
  "Pierre qui roule n’amasse pas mousse": true,
  "Pierre philosophale": true,
  "Pierre Bourdieu": true,
  "Pierre": true,
  "Piège en haute mer": true,
  "Piège de cristal": true,
  "Pieds-noirs": true,
  "Pied-de-biche": true,
  "Pied de nez": true,
  "Pied au plancher": true,
  "Pied à terre": true,
  "Pièce rapportée": true,
  "Pièce jointe": true,
  "Pic vert": true,
  "PIB (Produit intérieur brut)": true,
  "Pi": true,
  "Physique quantique": true,
  "Physique": true,
  "Phrase": true,
  "Photosynthèse": true,
  "Philosophie": true,
  "Philippines": true,
  "Philip Morris": true,
  "Philémon": true,
  "Pharmacien": true,
  "PH (Potentiel hydrogène)": true,
  "Peur bleue": true,
  "Peugeot": true,
  "Petra": true,
  "Petit-fils": true,
  "Petite-fille": true,
  "Petit Ours Brun": true,
  "Petit joueur": true,
  "Petit Bateau": true,
  "Petit à petit, l’oiseau fait son nid": true,
  "Pesanteur": true,
  "Perturbateur endocrinien": true,
  "Persepolis": true,
  "Persée": true,
  "Perse": true,
  "Périclès": true,
  "Père": true,
  "Perdre son temps": true,
  "Perdre les pédales": true,
  "Perdre le fil": true,
  "Perdre la tête": true,
  "Percer à jour": true,
  "Péplum": true,
  "Pénicilline": true,
  "Péniche": true,
  "Pendre la crémaillère": true,
  "Pèlerinage de Saint-Jacques-de-Compostelle": true,
  "Pelé": true,
  "Peignoir de bain": true,
  "Pédiatre": true,
  "Péché capital": true,
  "Peau d'Âne": true,
  "Pays-Bas": true,
  "Paysage": true,
  "Payer rubis sur l'ongle": true,
  "Payer en monnaie de singe": true,
  "Payer avec un lance-pierre": true,
  "Pauvre diable": true,
  "Pauvre comme Job": true,
  "Patrick Bruel": true,
  "Patinage artistique": true,
  "Patience et longueur de temps font plus que force ni que rage": true,
  "Pastilles pour la gorge": true,
  "Passerelle": true,
  "Passer un savon": true,
  "Passer sur le billard": true,
  "Passer son chemin": true,
  "Passer ses nerfs sur quelqu'un": true,
  "Passer l'éponge": true,
  "Passer le temps": true,
  "Passer l'arme à gauche": true,
  "Passer entre les gouttes": true,
  "Passer au fil de l'épée": true,
  "Passer à travers les mailles du filet": true,
  "Passer à tabac": true,
  "Passer à l'as": true,
  "Passage piéton": true,
  "Pas vu, pas pris": true,
  "Pas de printemps pour Marnie": true,
  "Pas de nouvelle, bonnes nouvelles": true,
  "Partir en vrille": true,
  "Partir en live": true,
  "Partir en fumée": true,
  "Partir c’est mourir un peu": true,
  "Partir à vau l'eau": true,
  "Parler pour ne rien dire": true,
  "Parler dans sa barbe": true,
  "Parler chiffons": true,
  "Parler boutique": true,
  "Parler à mots couverts": true,
  "Parle avec elle": true,
  "Paris Match": true,
  "Parenthèse": true,
  "Pardon": true,
  "Paracétamol": true,
  "Par la force des choses": true,
  "Paquet": true,
  "Pâques": true,
  "Pâquerette": true,
  "Papier": true,
  "Panthéon": true,
  "Panneau \"stop\"": true,
  "Panneau \"Hollywood\" (Los Angeles)": true,
  "Panneau": true,
  "Panier de crabe": true,
  "Pancréas": true,
  "Pakistan": true,
  "Paix": true,
  "Page": true,
  "Pablo Picasso": true,
  "Pablo Escobar": true,
  "Oz": true,
  "Oxymore": true,
  "Ouvrir son cœur": true,
  "Ouvrir l'œil": true,
  "Ouvrir les yeux": true,
  "Ouvrir les hostilités": true,
  "Outil": true,
  "Où est Charlie ?": true,
  "OTAN (Organisation du traité de l'Atlantique nord)": true,
  "Ostéopathe": true,
  "OSS 117": true,
  "Osiris": true,
  "Oscar": true,
  "Ortie": true,
  "Orson Welles": true,
  "Orphée": true,
  "Orgueil et Préjugés": true,
  "Orgue": true,
  "Ordre": true,
  "Orchestre": true,
  "Orange mécanique": true,
  "Orange Is the New Black": true,
  "Orage": true,
  "Oprah Winfrey": true,
  "Opiner du chef": true,
  "Opération Dragon": true,
  "Opéra de Sydney": true,
  "Opéra": true,
  "Opel": true,
  "One Direction": true,
  "Onduler de la toiture": true,
  "On peut rire de tout, mais pas avec tout le monde": true,
  "On ne sait jamais": true,
  "On ne prête qu’aux riches": true,
  "On ne peut être à la fois au four et au moulin": true,
  "On ne fait pas d’omelette sans casser des œufs": true,
  "On n'attrape pas des mouches avec du vinaigre": true,
  "On n’apprend pas à un vieux singe à faire la grimace": true,
  "On connaît la chanson": true,
  "On achève bien les chevaux": true,
  "OMS (Organisation mondiale du commerce)": true,
  "Olympique de Marseille": true,
  "Olive et Tom": true,
  "Olaf": true,
  "Oiseau de malheur": true,
  "OGM (Organisme Génétiquement Modifié)": true,
  "Œil pour œil, dent pour dent": true,
  "Œil de lynx": true,
  "Œil au beurre noir": true,
  "Œdipe": true,
  "Octet": true,
  "Ocean's Eleven": true,
  "Océanie": true,
  "Océan Pacifique": true,
  "Océan Indien": true,
  "Océan Atlantique": true,
  "O'Brother": true,
  "N'y voir que du feu": true,
  "Numéro de sécurité sociale": true,
  "Nul n’est prophète en son pays": true,
  "Nul n’est censé ignorer la loi": true,
  "Nuits blanches à Seattle": true,
  "Nuit et brouillard": true,
  "Nuit blanche": true,
  "Nu comme un ver": true,
  "NRJ": true,
  "Noyer le poisson": true,
  "Novak Djokovic": true,
  "Nouvelle-Zélande": true,
  "Nouvelle Vague": true,
  "Nouvelle": true,
  "Nouveau roman": true,
  "Nos étoiles contraires": true,
  "Norvège": true,
  "Nombre premier": true,
  "Nombre pair": true,
  "Nombre d'or": true,
  "Nombre": true,
  "Noircir le tableau": true,
  "Noir de monde": true,
  "Noam Chomsky": true,
  "No Country for Old Men": true,
  "Nintendo": true,
  "Nikola Tesla": true,
  "Nikita": true,
  "Nigéria": true,
  "Nid de poule": true,
  "Nicolas Copernic": true,
  "Niche écologique": true,
  "Nice": true,
  "Ni vu ni connu": true,
  "New York, New York": true,
  "New York": true,
  "New Delhi": true,
  "Nestlé": true,
  "Nescafé": true,
  "Nerf": true,
  "Neptune": true,
  "Népotisme": true,
  "N'en avoir rien à secouer": true,
  "N'en avoir rien à braire": true,
  "Nelson Mandela": true,
  "Nécessité fait loi": true,
  "Néant": true,
  "Ne toucher qu'avec les yeux": true,
  "Ne plus savoir sur quel pied danser": true,
  "Ne pas y aller par quatre chemins": true,
  "Ne pas y aller de main morte": true,
  "Ne pas y aller avec le dos de la cuillère": true,
  "Ne pas voir plus loin que le bout de son nez": true,
  "Ne pas valoir un pet de lapin": true,
  "Ne pas valoir un clou": true,
  "Ne pas valoir la corde pour le pendre": true,
  "Ne pas sentir la rose": true,
  "Ne pas se moucher du coude": true,
  "Ne pas se bousculer au portillon": true,
  "Ne pas perdre une seconde": true,
  "Ne pas perdre une miette": true,
  "Ne pas mettre tous les œufs dans le même panier": true,
  "Ne pas manger de ce pain-là": true,
  "Ne pas mâcher ses mots": true,
  "Ne pas l'emporter au paradis": true,
  "Ne pas lâcher d'une semelle": true,
  "Ne pas être sorti de l'auberge": true,
  "Ne pas être piqué des vers": true,
  "Ne pas être piqué des hannetons": true,
  "Ne pas être dans son assiette": true,
  "Ne pas être à prendre avec des pincettes": true,
  "Ne pas en revenir": true,
  "Ne pas casser trois pattes à un canard": true,
  "Ne pas avoir un sou vaillant": true,
  "Ne pas avoir sa langue dans sa poche": true,
  "Ne pas avoir les yeux en face des trous": true,
  "Ne pas avoir la tête à quelque chose": true,
  "Ne pas avoir inventé le fil à couper le beurre": true,
  "Ne pas avoir gardé les cochons ensemble": true,
  "Ne pas avoir froid aux yeux": true,
  "Ne pas arriver à la cheville": true,
  "Ne faire ni une ni deux": true,
  "Ne faire ni chaud ni froid": true,
  "Ne connaître ni d'Ève ni d'Adam": true,
  "NCIS": true,
  "NBA (National Basketball Association)": true,
  "N'avoir rien à voir avec": true,
  "N'avoir que la peau sur les os": true,
  "N'avoir ni queue ni tête": true,
  "Natation synchronisée": true,
  "Natalie Portman": true,
  "Nantes": true,
  "Nager comme un fer à repasser": true,
  "Mylène Farmer": true,
  "Mygale": true,
  "Musique tsigane": true,
  "Musique folk": true,
  "Musique electro": true,
  "Musique contemporaine": true,
  "Musique classique": true,
  "Musée du Louvre": true,
  "Musclor": true,
  "Murena": true,
  "Mur de Berlin": true,
  "Mur d’escalade": true,
  "Muppet Show": true,
  "Multicolore": true,
  "Mulholland Drive": true,
  "Muguet": true,
  "Muet comme une tombe": true,
  "Muet comme une carpe": true,
  "Mr. et Mrs. Smith": true,
  "Mozzarella": true,
  "Moyen-Orient": true,
  "Moyen Âge": true,
  "Mowgli": true,
  "Mouton de Panurge": true,
  "Mourir sur scène": true,
  "Mourir dans son lit": true,
  "Moulin Rouge": true,
  "Moules frites": true,
  "Mouche du coche": true,
  "Motus et bouche cousue": true,
  "Moteur à explosion": true,
  "Mot": true,
  "Moscou": true,
  "Morse (alphabet)": true,
  "Morgan Freeman": true,
  "Mordre la poussière": true,
  "Mordre la main qui nourrit": true,
  "Mordre à l'hameçon": true,
  "Monument": true,
  "Monty Python : Sacré Graal !": true,
  "Montrer patte blanche": true,
  "Montréal": true,
  "Montpellier": true,
  "Montesquieu": true,
  "Monter quatre à quatre": true,
  "Monter sur ses grands chevaux": true,
  "Monter au créneau": true,
  "Montagnes Rocheuses": true,
  "Montagne Pelée": true,
  "Mont Rushmore": true,
  "Mont Olympe": true,
  "Mont Fuji": true,
  "Monstres et Cie": true,
  "Monsanto": true,
  "Monk": true,
  "Mondialisation": true,
  "Monde": true,
  "Monaco": true,
  "Mon voisin Totoro": true,
  "Mon petit doigt m'a dit": true,
  "Mon œil !": true,
  "Mon nom est Personne": true,
  "Mon dieu !": true,
  "Molière": true,
  "Molécule": true,
  "Moissonneuse-batteuse": true,
  "Mois": true,
  "Moi, moche et méchant": true,
  "Mohammed VI": true,
  "Mohamed Ali": true,
  "Moelle épinière": true,
  "Moby Dick": true,
  "Mitaines": true,
  "Mississippi": true,
  "Mission: impossible": true,
  "Miss France": true,
  "Miser sur le mauvais cheval": true,
  "Mise à pied": true,
  "Mireille Mathieu": true,
  "Mirage": true,
  "Minute papillon": true,
  "Minute": true,
  "Minuit": true,
  "Minority Report": true,
  "Minerve": true,
  "Mine de rien": true,
  "Mimi Cracra": true,
  "Million Dollar Baby": true,
  "Millénium": true,
  "Mike Tyson": true,
  "Mignonne, allons voir si la rose": true,
  "Mi-figue, mi-raisin": true,
  "Mieux vaut tard que jamais": true,
  "Mieux vaut prévenir que guérir": true,
  "Mieux vaut faire envie que pitié": true,
  "Mieux vaut être seul que mal accompagné": true,
  "Midi": true,
  "Microsoft": true,
  "Michel-Ange": true,
  "Michel Sardou": true,
  "Michel Platini": true,
  "Michel Drucker": true,
  "Michel Cymes": true,
  "Michel Berger": true,
  "Michael Schumacher": true,
  "Michael Phelps": true,
  "Michael Jordan": true,
  "Mexico": true,
  "Meurtre mystérieux à Manhattan": true,
  "Meurs un autre jour": true,
  "Meuble": true,
  "Mettre les voiles": true,
  "Mettre les points sur les i": true,
  "Mettre les pieds dans le plat": true,
  "Mettre les petits plats dans les grands": true,
  "Mettre le pied à l'étrier": true,
  "Mettre le nez dehors": true,
  "Mettre le grappin sur quelqu'un": true,
  "Mettre le doigt dans l'engrenage": true,
  "Mettre la puce à l'oreille": true,
  "Mettre la main à la pâte": true,
  "Mettre la clé sous la porte": true,
  "Mettre la charrue avant les bœufs": true,
  "Mettre en joue": true,
  "Mettre du beurre dans les épinards": true,
  "Mettre de l'huile dans les rouages": true,
  "Mettre de l'eau dans son vin": true,
  "Mettre au pied du mur": true,
  "Mettre à l'index": true,
  "Mettre à la porte": true,
  "Mettre à genoux": true,
  "Metropolis": true,
  "Mètre": true,
  "Métaphore": true,
  "Metallica": true,
  "Mésopotamie": true,
  "Meryl Streep": true,
  "Méridien": true,
  "Mère": true,
  "Mercure": true,
  "Mercredi des Cendres": true,
  "Mercedes": true,
  "Mer Rouge": true,
  "Mer Noire": true,
  "Mer Méditerranée": true,
  "Mer Égée": true,
  "Mer du Nord": true,
  "Mer de Chine": true,
  "Mer d'Arabie (Mer d'Oman)": true,
  "Mer": true,
  "Mentir comme un arracheur de dents": true,
  "Mentalist": true,
  "Mensonge": true,
  "Mener une vie de bâton de chaise": true,
  "Mener par le bout du nez": true,
  "Mener grand train": true,
  "Mener en bateau": true,
  "Mener à la baguette": true,
  "Ménager la chèvre et le chou": true,
  "Men in Black": true,
  "Memento": true,
  "Mélanger les torchons et les serviettes": true,
  "Méduser": true,
  "Méditation": true,
  "Mediapart": true,
  "Maya l'abeille": true,
  "Maya": true,
  "Maximilien de Robespierre": true,
  "Matin": true,
  "Matelas": true,
  "Maté": true,
  "Match Point": true,
  "Mata Hari": true,
  "Massacre à la tronçonneuse": true,
  "Mary Poppins": true,
  "Mary à tout prix": true,
  "Marxisme": true,
  "Marvel": true,
  "Martre": true,
  "Martin-pêcheur": true,
  "Martin Scorsese": true,
  "Martin Luther King": true,
  "Martin Luther": true,
  "Marteau-piqueur": true,
  "Marseille": true,
  "Mars Attacks!": true,
  "Mars": true,
  "Marquer le pas": true,
  "Maroc": true,
  "Marlene Dietrich": true,
  "Mark Zuckerberg": true,
  "Marion Cotillard": true,
  "Marine Le Pen": true,
  "Marilyn Monroe": true,
  "Marie-Antoinette": true,
  "Marie (mère de Jésus)": true,
  "Marianne": true,
  "Mariage pluvieux, mariage heureux": true,
  "Mariage civil": true,
  "Maria Sharapova": true,
  "Margaret Thatcher": true,
  "Marelle": true,
  "Mare": true,
  "Mardi gras": true,
  "Marco Polo": true,
  "Marcher sur les plates bandes de quelqu'un": true,
  "Marcher sur les pieds de quelqu'un": true,
  "Marcher sur des œufs": true,
  "Marcher à pas de loup": true,
  "Maquillé(e) comme un camion volé": true,
  "Mao Zedong": true,
  "Manuel Valls": true,
  "Manon des sources": true,
  "Manichéisme": true,
  "Mangouste": true,
  "Manger sur le pouce": true,
  "Manger les pissenlits par la racine": true,
  "Manger dans la main": true,
  "Manger avec un lance-pierre": true,
  "Mandarin": true,
  "Manchot empereur": true,
  "Manchester United": true,
  "Mammifère": true,
  "Maman, j'ai raté l'avion": true,
  "Maman les p'tits bateaux": true,
  "Malin comme un singe": true,
  "Maldives": true,
  "Malcolm X": true,
  "Maison-Blanche": true,
  "Maire": true,
  "Maintenant": true,
  "Main pleine de doigts": true,
  "Main dans la main": true,
  "Main courante": true,
  "Maigre comme un clou": true,
  "Mai 68": true,
  "Magnum": true,
  "Madrid": true,
  "Madonna": true,
  "Madame Bovary": true,
  "Madagascar": true,
  "Mad Max": true,
  "Machu Picchu": true,
  "Mâchoire": true,
  "Machine à vapeur": true,
  "Mâcher le travail": true,
  "MacGyver": true,
  "M. Mouche": true,
  "M. et Mme Patate": true,
  "M le Maudit": true,
  "M (Matthieu Chedid)": true,
  "Lyon": true,
  "Lynx": true,
  "LVMH": true,
  "Luxembourg": true,
  "Lutte gréco-romaine": true,
  "Lundi de Pentecôte": true,
  "Lumière": true,
  "Luke Skywalker": true,
  "Ludwig van Beethoven": true,
  "Lucy": true,
  "Luc Besson": true,
  "Loup de mer": true,
  "Louise Michel": true,
  "Louis XVI": true,
  "Louis XIV": true,
  "Louis Pasteur": true,
  "Lost": true,
  "Los Angeles": true,
  "L'Oréal": true,
  "Long à la détente": true,
  "Londres": true,
  "Lolita": true,
  "LOL": true,
  "Loire": true,
  "Loin des yeux, loin du cœur": true,
  "Loi de Murphy": true,
  "Logique": true,
  "Logiciel": true,
  "Loft Story": true,
  "L'Odyssée de Pi": true,
  "L'Odyssée": true,
  "Lobby": true,
  "Livre dont vous êtes le héros": true,
  "Littérature": true,
  "Lits superposés": true,
  "Litre": true,
  "Liste": true,
  "Lisbeth Salander": true,
  "Liquide": true,
  "Lionel Messi": true,
  "Line Renaud": true,
  "Lille": true,
  "Liliane Bettencourt": true,
  "L'Île du Docteur Moreau": true,
  "L'Île au trésor": true,
  "Liker": true,
  "Ligue des champions": true,
  "Ligne": true,
  "L'Idiot": true,
  "Libre arbitre": true,
  "Liberté": true,
  "Libération": true,
  "Libéralisme": true,
  "Liban": true,
  "Liam Neeson": true,
  "L'homme/la femme de la situation": true,
  "L'Homme qui valait 3 milliards": true,
  "L'Homme qui murmurait à l'oreille des chevaux": true,
  "L'homme invisible": true,
  "L'Histoire sans fin": true,
  "L'Exorciste": true,
  "Lever le coude": true,
  "Lever l'ancre": true,
  "L'Être et le Néant": true,
  "L'Étranger": true,
  "L'Étrange Noël de Monsieur Jack": true,
  "L'Étrange histoire de Benjamin Button": true,
  "L'étincelle qui met le feu aux poudres": true,
  "L'Étalon Noir": true,
  "Les Yeux d'Elsa": true,
  "Les Voyages de Gulliver": true,
  "Les Visiteurs": true,
  "Les Valseuses": true,
  "Les Vacances de Monsieur Hulot": true,
  "Les Trois petits cochons": true,
  "Les Trois mousquetaires": true,
  "Les Trois frères": true,
  "Les Triplettes de Belleville": true,
  "Les Tortues Ninja": true,
  "Les Tontons flingueurs": true,
  "Les Tiny Toons": true,
  "Les Temps modernes": true,
  "Les Sous-doués": true,
  "Les Soprano": true,
  "Les Simpson": true,
  "Les Sept Samouraïs": true,
  "Les Rougon-Macquart": true,
  "Les rats quittent le navire": true,
  "Les Raisins de la colère": true,
  "Les quatre fers en l'air": true,
  "Les Quatre cents coups": true,
  "Les plaisanteries les plus courtes sont les meilleures": true,
  "Les Pierrafeu": true,
  "Les petits ruisseaux font les grandes rivières": true,
  "Les paroles s’envolent, les écrits restent": true,
  "Les Parapluies de Cherbourg": true,
  "Les Oiseaux": true,
  "Les Noces funèbres": true,
  "Les Noces de Figaro": true,
  "Les Mystérieuses Cités d'or": true,
  "Les Mystères de l'Ouest": true,
  "Les murs ont des oreilles": true,
  "Les Monstroplantes": true,
  "Deltaplane": true,
  "Etang": true,
  "Lumiere": true,
  "Autoradio": true,
  "Escalator/escalier": true,
  "Pyramide": true,
  "Pompiers": true,
  "Index": true,
  "Robe de mariée": true,
  "Oeuf de Pâques": true,
  "Pêcher": true,
  "Squash": true,
  "Cachalot": true,
  "Hockey sur gazon": true,
  "Fourmilier": true,
  "Réalisateur": true,
  "Formule 1": true,
  "Curling": true,
  "Spéléologie": true,
  "Samedi": true,
  "Ours polaire": true,
  "Neveu": true,
  "Déménager": true,
  "Tailleur": true,
  "Chou de bruxelles": true,
  "Huître": true,
  "Lampe à huile": true,
  "Artichaut": true,
  "Esthéticienne": true,
  "Marionnettiste": true,
  "Président de la république": true,
  "Sage-femme": true,
  "Autostop": true,
  "Ku Klux Klan": true,
  "Koh-Lanta": true,
  "Kinésithérapeute": true,
  "Kim Kardashian": true,
  "Kill Bill": true,
  "Kilimandjaro": true,
  "Kiffer": true,
  "Kermesse": true,
  "Ken le survivant": true,
  "Karl Marx": true,
  "Karl Lagerfeld": true,
  "Karaté Kid": true,
  "Kaamelott": true,
  "K 2000": true,
  "Justin Trudeau": true,
  "Justin Bieber": true,
  "Justaucorps": true,
  "Jusqu'à plus soif": true,
  "Jusqu'à nouvel ordre": true,
  "Jurassic Park": true,
  "Jupon": true,
  "Jupiter": true,
  "Julien Clerc": true,
  "Julia Roberts": true,
  "Jules Ferry": true,
  "Jules et Jim": true,
  "Jules César": true,
  "Juifs": true,
  "Judaïsme": true,
  "Jour de l'an": true,
  "Jouer son va-tout": true,
  "Jouer du pipeau": true,
  "Jouer cartes sur table": true,
  "Jouer avec le feu": true,
  "Jouer au chat et à la souris": true,
  "Joseph Staline": true,
  "Joindre les deux bouts": true,
  "Johnny Hallyday": true,
  "Johnny Depp": true,
  "John Lennon": true,
  "John Fitzgerald Kennedy": true,
  "John Davison Rockefeller": true,
  "Johannes Vermeer": true,
  "Johannes Gutenberg": true,
  "Johan et Pirlouit": true,
  "JoeyStarr": true,
  "Joel et Ethan Coen": true,
  "Joe Dalton": true,
  "Jiminy Cricket": true,
  "Jimi Hendrix": true,
  "Jeux olympiques d'hiver": true,
  "Jeux olympiques d'été": true,
  "Jeux olympiques antiques": true,
  "Jeux interdits": true,
  "Jeune loup aux dents longues": true,
  "Jeudi de l'Ascension": true,
  "Jeu de rôle": true,
  "Jeu de piste": true,
  "Jeu de main, jeu de vilain": true,
  "Jeu de Go": true,
  "Jeu": true,
  "Jeter un œil": true,
  "Jeter un froid": true,
  "Jeter son argent par les fenêtres": true,
  "Jeter de l'huile sur le feu": true,
  "Jeter aux oubliettes": true,
  "Jesus Christ Superstar": true,
  "Jérusalem": true,
  "Jennifer Lopez": true,
  "Jennifer Lawrence": true,
  "Jennifer Aniston": true,
  "Jenifer": true,
  "Jean-Sébastien Bach": true,
  "Jean-Pierre Pernaut": true,
  "Jean-Paul Sartre": true,
  "Jeanne et Serge": true,
  "Jean-Luc Mélenchon": true,
  "Jean-Jacques Goldman": true,
  "Jean Ferrat": true,
  "Jean Dujardin": true,
  "Jean de La Fontaine": true,
  "Je vais bien, ne t'en fais pas": true,
  "Je te tiens, tu me tiens par la barbichette": true,
  "Je suis venu, j'ai vu, j'ai vaincu": true,
  "Je suis une légende": true,
  "Jardiner": true,
  "James Bond 007 contre Dr No": true,
  "Jamais deux sans trois": true,
  "Jamais": true,
  "Jamaïque": true,
  "Jalousie": true,
  "Jakarta": true,
  "J'ai du bon tabac": true,
  "Jacquie et Michel": true,
  "Jacques-Yves Cousteau": true,
  "Jacques Mesrine": true,
  "Jacques Dutronc": true,
  "Jacques Brel": true,
  "Jack l'Éventreur": true,
  "Jabba le Hut": true,
  "Iznogoud": true,
  "Istanbul": true,
  "Israël": true,
  "Islande": true,
  "Islam": true,
  "Isis": true,
  "ISF (Impôt de solidarité sur la fortune)": true,
  "ISBN (International Standard Book Number)": true,
  "Isabelle Adjani": true,
  "Isaac Newton": true,
  "IRM (Imagerie par résonance magnétique)": true,
  "Irlande": true,
  "Iran": true,
  "Invictus": true,
  "Intrus": true,
  "Intouchables": true,
  "Interstellar": true,
  "Interprète": true,
  "Internet Movie Database": true,
  "Intermarché": true,
  "Intelligence artificielle": true,
  "Inox": true,
  "Inglorious Basterds": true,
  "Informatique": true,
  "Inflation": true,
  "Infini": true,
  "Indonésie": true,
  "Indice de masse corporelle": true,
  "Indiana Jones et le temple maudit": true,
  "Indiana Jones et la dernière croisade": true,
  "Independence Day": true,
  "Inde": true,
  "Inception": true,
  "Incendie": true,
  "Inca": true,
  "In the Mood for Love": true,
  "Impressionnisme": true,
  "Impression Soleil Levant": true,
  "Imperméable": true,
  "Imiter": true,
  "Imam": true,
  "Iliade": true,
  "Îles Canaries": true,
  "Île-de-France": true,
  "Île Maurice": true,
  "Île de Pâques": true,
  "Il va y avoir du sport": true,
  "Il tape sur des bambous": true,
  "Il pleut, il pleut, bergère": true,
  "Il Pleure dans mon Cœur": true,
  "Il n'y a que les imbéciles qui ne changent jamais d’avis": true,
  "Il n'y a pas un chat": true,
  "Il n'y a pas le feu au lac": true,
  "Il n'y a pas de petits profits": true,
  "Il ne faut pas pousser mémé dans les orties": true,
  "Il n’y a que le premier pas qui coûte": true,
  "Il n’y a que la vérité qui blesse": true,
  "Il n’y a pas de sot métier": true,
  "Il n’y a pas de quoi fouetter un chat": true,
  "Il n’y a pas de fumée sans feu": true,
  "Il n’est pire sourd que celui qui ne veut pas entendre": true,
  "Il jouait du piano debout": true,
  "Il faut se méfier de l’eau qui dort": true,
  "Il faut sauver le soldat Ryan": true,
  "Il faut que jeunesse se passe": true,
  "Il faut manger pour vivre, et non vivre pour manger": true,
  "Il faut de tout pour faire un monde": true,
  "Il faut battre le fer quand il est chaud": true,
  "Il était une fois… l'Homme": true,
  "Il était une fois… la Vie": true,
  "Il était une fois dans l'Ouest": true,
  "Il était une fois": true,
  "Il était une bergère": true,
  "Il était un petit navire": true,
  "Il est cinq heures, Paris s'éveille": true,
  "Il court, il court, le furet": true,
  "Igor et Grichka Bogdanoff": true,
  "Idiocracy": true,
  "IDH (Indice de développement humain)": true,
  "Ibrahim Maalouf": true,
  "I, Robot": true,
  "Hygiène": true,
  "Hurler avec les loups": true,
  "Hunger Games": true,
  "Humide": true,
  "Humanisme": true,
  "Hubble": true,
  "HTTP (Hypertext Transfer Protocol)": true,
  "How I Met Your Mother": true,
  "House of Cards": true,
  "Hotel California": true,
  "Horreur": true,
  "Hook (ou la Revanche du Capitaine Crochet)": true,
  "Honoré de Balzac": true,
  "Honni soit qui mal y pense": true,
  "Hong Kong": true,
  "Homosexualité": true,
  "Homo sapiens": true,
  "Homme de Neanderthal": true,
  "Homère": true,
  "Homeland": true,
  "Homard": true,
  "Histoire": true,
  "Hiroshima": true,
  "Hindouisme": true,
  "Hindi": true,
  "Himalaya": true,
  "Highlander": true,
  "Hier": true,
  "Hibernatus": true,
  "Heure d'été": true,
  "Heure": true,
  "Héroïne": true,
  "Hermine": true,
  "Hermaphrodite": true,
  "Hérédité": true,
  "Henri Matisse": true,
  "Henri IV": true,
  "Hélène et les garçons": true,
  "Hédonisme": true,
  "Hectare": true,
  "Heavy metal": true,
  "Hayao Miyazaki": true,
  "Hawaï": true,
  "Hautbois": true,
  "Haut comme trois pommes": true,
  "Hashtag (#)": true,
  "Haschich": true,
  "Harry Callahan (L'inspecteur Harry)": true,
  "Harrison Ford": true,
  "Harpe": true,
  "Haribo": true,
  "Hard rock": true,
  "Hanoucca": true,
  "Hannibal Lecter": true,
  "Handicapé": true,
  "Hanche": true,
  "Han Solo": true,
  "Hamlet": true,
  "Haltérophilie": true,
  "Haïti": true,
  "Guy de Maupassant": true,
  "Gustave Eiffel": true,
  "Guillemet": true,
  "Guide Michelin": true,
  "Guerre froide": true,
  "Guerre et Paix": true,
  "Guerre du Viêt Nam": true,
  "Guerre du Golfe": true,
  "Guerre d'Irak": true,
  "Guerre d'Indochine": true,
  "Guerre d'Espagne": true,
  "Guerre de Troie": true,
  "Guerre de Sécession": true,
  "Guerre de Corée": true,
  "Guerre de Cent Ans": true,
  "Guerre d'Algérie": true,
  "Guernica": true,
  "Guérir": true,
  "Guadeloupe": true,
  "Groupe sanguin": true,
  "Grossesse": true,
  "Grosse caisse": true,
  "Grosminet": true,
  "Groenland": true,
  "Grizzli": true,
  "Grincheux": true,
  "Grimper au rideau": true,
  "Griller": true,
  "Grey's Anatomy": true,
  "Gremlins": true,
  "Greffe": true,
  "Gravity": true,
  "Gravé dans le marbre": true,
  "Grands Lacs": true,
  "Grand-père": true,
  "Grand-mère": true,
  "Grandir": true,
  "Grande-Bretagne": true,
  "Grande Barrière de Corail": true,
  "Grand Chelem": true,
  "Grand Canyon": true,
  "Gran Torino": true,
  "Grammaire": true,
  "Graisser la patte": true,
  "Graine": true,
  "Grace Kelly": true,
  "Gospel": true,
  "Google Traduction": true,
  "Google Maps": true,
  "Google": true,
  "Good Morning, Vietnam": true,
  "Goldorak": true,
  "Goldfinger": true,
  "GoldenEye": true,
  "Golden Gate Bridge": true,
  "God Save the Queen": true,
  "Glucose": true,
  "Glande thyroïde": true,
  "Glacer le sang": true,
  "Ghostbusters": true,
  "Ghost": true,
  "Germinal": true,
  "Gérard Depardieu": true,
  "Gepetto": true,
  "Georges Clemenceau": true,
  "Georges Brassens": true,
  "George Clooney": true,
  "Géométrie": true,
  "Géographie": true,
  "YouPorn": true,
  "Vulve": true,
  "Viol": true,
  "Vagin": true,
  "Une main baladeuse": true,
  "Une partie de jambes en l'air": true,
  "Tuberculose": true,
  "Trouer le cul": true,
  "Troisième Reich": true,
  "Trisomie 21": true,
  "Transsexuel": true,
  "Trafic d'influence": true,
  "Torture": true,
  "Tirer une latte": true,
  "Tête de Turc": true,
  "Tendu comme un string": true,
  "Tchernobyl": true,
  "Syphilis": true,
  "Sperme": true,
  "Sodomie": true,
  "Sida": true,
  "Shoah": true,
  "Sexisme": true,
  "S'entendre comme cul et chemise": true,
  "S'en torcher le cul": true,
  "S'en battre l'œil": true,
  "S'en battre les couilles": true,
  "Seconde Guerre mondiale": true,
  "Se vider les couilles": true,
  "Se sortir les doigts du cul": true,
  "Se magner le cul": true,
  "Se faire voir chez les Grecs": true,
  "Se faire des couilles en or": true,
  "Schizophrénie": true,
  "Sado-masochisme": true,
  "Rougeole": true,
  "Rocco Siffredi": true,
  "Rêve érotique": true,
  "Résurrection": true,
  "Régime de Vichy": true,
  "Rage": true,
  "Racisme": true,
  "Proxénétisme": true,
  "Promesse de gascon": true,
  "Procès de Nuremberg": true,
  "Première Guerre mondiale": true,
  //*/
};

export default {
  name: 'AdminRechercheMots',

  data() {
    return {
      state: 0,

      cur_mot: null,
      mots_restants: [],
      cur_resultats: null,

      form: {
        mots: '',
      },

      mots_rejetes: [],
      mots_gardes: [],

      output_rejets: '',
      output_go: '',

      cumulative_fails: 0,

      current_word_index: 0,
      total_words: 0,
    };
  },

  created() {
    window.addEventListener('keydown', this.arrowListener);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.arrowListener);
  },

  computed: {
    garderImpossible() {
      return this.cur_resultats !== null && this.cur_resultats.length > 0 && this.cur_resultats[0].score === 100;
    },
  },

  methods: {
    sprotch() {
      this.mots_restants = this.form.mots.split('\n');
      this.total_words = this.mots_restants.length;
      this.current_word_index = 0;

      this.mots_rejetes = [];
      this.mots_gardes = [];

      this.state = 1;
      this.computeNextWord();
    },

    arrowListener(event) {
      if (this.state === 2) {
        if (event.key === 'ArrowRight') {
          this.boutonGarder();
        }

        if (event.key === 'ArrowLeft') {
          this.boutonRejeter();
        }
      }
    },

    boutonGarder() {
      this.mots_gardes.push(this.cur_mot);
      this.state = 1;
      this.computeNextWord();
    },

    boutonRejeter() {
      this.mots_rejetes.push(this.cur_mot);
      this.state = 1;
      this.computeNextWord();
    },

    stopAfficheGo() {
      this.state = 3;
      this.output_go = '';

      for (let i in this.mots_gardes) {
        this.output_go += '`';
        this.output_go += this.mots_gardes[i];
        this.output_go += '`,\n';
      }

      this.output_rejets = '';
      for (let i in this.mots_rejetes) {
        this.output_rejets += JSON.stringify(this.mots_rejetes[i]);
        this.output_rejets += ': true,\n';
      }
    },

    computeNextWord() {
      if (this.mots_restants.length === 0) {
        this.stopAfficheGo();
        return;
      }

      this.current_word_index++;
      this.cur_mot = this.mots_restants.pop();
      this.cur_resultats = null;

      if (this.cur_mot in liste_rejet) {
        this.cumulative_fails += 1;
        this.computeNextWord();
        return;
      }

      this.$axios.post(this.$url_serveur + '/api/admin/6KJ4yOlsrK2ueH9NYve8GqMwjgS6fSavaLBf4mqT/recherche_mots_fr', {
        mot: this.cur_mot,
      })
      .then((resp) => {
        if (resp.data.length > 0 && resp.data[0].score === 100) {
          this.cumulative_fails += 1;
          this.computeNextWord();
        } else {
          this.cumulative_fails = 0;
          this.state = 2;
          this.cur_resultats = resp.data;
        }
      });
    },
  },
}
</script>